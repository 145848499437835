<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSixAmount {
        .tabs {
            border-bottom:1px solid #aaa; position:relative; height:1.8rem; line-height:1.8rem; width:100%; overflow:hidden; color:#999;
            li {
                position:relative; padding:0 .6rem; bottom:2px; border-bottom:3px solid $color-n; float:left; font-size:.7rem; cursor:pointer;
                &.active{
                    border-color:$color-t; font-weight:bold; color:#333;
                }
            }
        }
        .el-table__expanded-cell[class*=cell]{
            padding:14px 40px 8px 40px;
            h4 {font-size:19px;}
        }
        .breadcrumb {
            background-color:#f5f5f5; border-radius:4px; padding:10px 12px;
        }
        .el-progress-bar__outer {
            background-color:#dbdde2;
        }
        [mark=true] {
            color:red!important;
        }
        .el-progress-bar__inner {
            background-color:#42d636;
        }
    }
</style>
<template>
    <div class="CreditSix CreditSixAmount">
        <div v-if="target && target.type == 1" class="ctrl o-mb">
            <div v-if="summary && summary.status == 0">
                <div class="c-text-c o-ptb-h">
                    当前系统账单已全部结算
                </div>
            </div>
            <div v-else-if="main._list === 'opening'" class="o-ptb-h c-text-c">
                <div class="o-pb">系统颁奖过账中，请稍后重试</div>
                <Button @click="init()">刷新</Button>
            </div>
            <div v-else-if="main._init" ref="table">

                <template v-if="Filter.games_type < 2">

<!-- xac -->
                    <table id="CreditSixAmount-table-2" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0">
                        <colgroup>
                            <col width="130">
                            <col>
                            <col v-if="Opt.isTeam">
                            <col v-if="Filter.status == 0 && Opt.isTeam">
                            <col>
                            <col>
                            <col v-if="!Opt.isTeam">
                            <col v-if="!Opt.isTeam">
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:center;">
                                <td style="background-color: rgb(248 216 197);" rowspan="2" @click="puls_reduce('xac')"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                    <span id="xac_puls" style="font-size:16px;">-</span>帐号(新澳门)</div>
                                </td>
                                <td style="background-color: rgb(248 216 197);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">名称</div></td>
                                <td v-if="Opt.isTeam" style="background-color: rgb(248 216 197);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">级别</div></td>
                                <td v-if="Filter.status == 0 && Opt.isTeam" style="background-color: rgb(248 216 197);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">关系</div></td>
                                <td style="background-color: rgb(248 216 197);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下单数</div></td>
                                <td style="background-color: rgb(248 216 197);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下注金额</div></td>
                                <!-- <td v-if="Opt.isTeam" style="background-color: rgb(248 216 197);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">有效金额</div></td> -->
                                <td v-if="!Opt.isTeam" style="background-color: rgb(248 216 197);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">奖金</div></td>
                                <td v-if="!Opt.isTeam" style="background-color: rgb(248 216 197);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">退水</div></td>
                                <td style="background-color: rgb(248 216 197);" rowspan="2">
                                    <div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                        <span v-if="Opt.isTeam">会员结果</span>
                                        <span v-else>结果</span>
                                    </div>
                                </td>
                                <template v-if="Opt.isTeam && Filter.status != 0">
                                    <td style="background-color: rgb(248 216 197);" rowspan="2">{{ isTop ? '代理结果' : '交收' }}</td>
                                    <td v-if="!isTop" style="text-align:center; background-color: rgb(248 216 197);" colspan="4">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                    <td v-if="!isTop" style="text-align:center; background-color: rgb(248 216 197);" colspan="5">代理收飞</td>
                                    <td v-if="isTop" style="background-color: rgb(248 216 197);" rowspan="2">收飞金额</td>
                                    <td v-if="isTop" style="background-color: rgb(248 216 197);" rowspan="2">收飞结果</td>
                                    <td style="background-color: rgb(248 216 197);" rowspan="2">总结果</td>
                                    <td v-if="!isTop && Filter.pid != 1" style="background-color: rgb(248 216 197);" rowspan="2">上级结果</td>
                                    <td style="background-color: rgb(248 216 197);" rowspan="2">贡献</td>
                                    <td v-if="Filter.pid != 1" style="background-color: rgb(248 216 197);" rowspan="2">贡献2</td>
                                    <td style="background-color: rgb(248 216 197);" rowspan="2">贡献比例</td>
                                </template>
                            </tr>
                            <tr v-if="Opt.isTeam && !isTop && Filter.status != 0" style="text-align:center;">
                                <td>退水</td>
                                <td>金额</td>
                                <td>占成</td>
                                <td>结果</td>
                                <td>退水</td>
                                <td>金额</td>
                                <td>输赢</td>
                                <td>占成</td>
                                <td>结果</td>
                            </tr>
                        </thead>
                        <tbody id="xac_data" style="text-align:left;">
                            <tr v-for="(item,index) in main._list" v-show="!!summary && !main._loading" :key="item.id">
                                <template v-if="item.xac_bets_num > 0">
                                    <td style="text-align:left;">
                                        <a class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,5)">{{ item.username }}</a>
                                    </td>
                                    <td style="text-align:left;">
                                        <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                                    </td>
                                    <td v-if="Opt.isTeam">
                                        <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                                    </td>
                                    <td v-if="Filter.status == 0 && Opt.isTeam">
                                        <a class="link" @click="LevelView(item)">关系</a>
                                    </td>
                                    <td style="text-align:left;">{{ item.xac_bets_num }}</td>
                                    <td style="text-align:left;">
                                        <a v-mark class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,5)">{{ item.xac_bets_amount }}</a>
                                    </td>

                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ item.xac_valid_amount }}</td> -->
                                    <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.xac_bets_bonus }}</td>
                                    <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.xac_percent_backwater_amount }}</td>
                                    <td v-mark style="text-align:left;">{{ item.xac_members_result }}</td>
                                    <template v-if="Opt.isTeam && Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ item.xac_js_result }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ Price(item.xac_percent_backwater_amount,{separate: false}) }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xac_percent_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xac_percent }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xac_percent_result }}</td>

                                            <td v-mark style="text-align:left;">{{ Price(item.xac_transfer_backwater,{separate: false}) }}</td>
                                            <td v-mark style="text-align:left;"><a class="link" @click="Edit(item,7)">{{ item.xac_transfer_amount }}</a></td>

                                            <td v-mark style="text-align:left;">{{ item.xac_transfer_wins }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xac_transfer }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xac_transfer_result }}</td>
                                        </template>

                                        <td v-if="isTop" style="text-align:left;"><a v-mark class="link" @click="Edit(item,7)">{{ item.xac_transfer_amount }}</a></td>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ item.xac_transfer_result }}</td>

                                        <td v-mark style="text-align:left;">{{ item.xac_result }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ item.xac_up_result }}</td>
                                        {{/* 奉献额与奉献比率 */}}
                                        <td v-mark style="text-align:left;">{{ item.xac_contribution_one }}</td>
                                        <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ item.xac_contribution_two }}</td>
                                        <td style="text-align:center;">
                                            <div class="l-flex-c o-pr-s">
                                                <el-progress class="PrintHide" style="width:55px;" :percentage="CalcuNumber(item.xac_contribution_proportion)" :show-text="false" />
                                                <p class="l-flex-1 c-text-6 c-text-r">{{ item.xac_contribution_proportion }}%</p>
                                            </div>
                                        </td>
                                    </template>
                                </template>
                            </tr>
                        </tbody>
                        <tfoot>
                            <!-- <tr v-if="main._empty">
                                <td class="empty" colspan="100">暂无数据</td>
                            </tr> -->
                            <template v-if="!main._empty && Opt.isTeam">
                                <tr v-if="main._count >= 50" class="subtotal">
                                    <td style="text-align:left;" colspan="3">小计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('xac_bets_num') }}</td>
                                    <td v-mark style="text-align:left;">{{ CalcuRow('xac_bets_amount') }}</td>
                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ CalcuRow('xac_valid_amount') }}</td> -->
                                    <td v-mark style="text-align:left;">{{ CalcuRow('xac_members_result') }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ CalcuRow('xac_js_result') }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xac_percent_backwater_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xac_percent_amount') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xac_percent_result') }}</td>

                                            <td v-mark style="text-align:left;">{{ CalcuRow('xac_transfer_backwater') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xac_transfer_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xac_transfer_wins') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xac_transfer_result') }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('xac_transfer_amount') }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('xac_transfer_result') }}</td>

                                        <td v-mark style="text-align:left;">{{ CalcuRow('xac_result') }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('xac_up_result') }}</td>
                                    </template>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('xac_contribution_one') }}</td>
                                    <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('xac_contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </tr>
                                <tr v-if="!main._empty && summary" v-show="!main._loading" class="total">
                                    <td style="text-align:left;" colspan="3">合计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ summary.xac_bets_num }}</td>
                                    <td v-mark style="text-align:left;">{{ summary.xac_bets_amount }}</td>
                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ summary.xac_valid_amount }}</td> -->
                                    <td v-mark style="text-align:left;">{{ summary.xac_members_result }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ summary.xac_js_result }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ summary.xac_percent_backwater_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.xac_percent_amount }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.xac_percent_result }}</td>

                                            <td v-mark style="text-align:left;">{{ summary.xac_transfer_backwater }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.xac_transfer_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.xac_transfer_wins }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.xac_transfer_result }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.xac_transfer_amount }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.xac_transfer_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.xac_result }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;" by="上级结果">{{ summary.xac_up_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.xac_contribution_one }}</td>
                                        <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ summary.xac_contribution_two }}</td>
                                        <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                    </template>
                                </tr>
                            </template>
                        </tfoot>
                    </table>
                    <template v-if="Opt.isTeam && summary && summary.xac_transfer_number_out > 0 && !main._loading">
                        <table v-if="Filter.status == 0" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <colgroup>
                            <col width="130">
                            <col width="195">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                            <thead>
                                <tr style="text-align:left;">
                                    <td style="background-color: rgb(248 216 197);">帐号</td>
                                    <td style="background-color: rgb(248 216 197);">走飞笔数</td>
                                    <td style="background-color: rgb(248 216 197);">走飞金额</td>
                                    <td style="background-color: rgb(248 216 197);">收飞笔数</td>
                                    <td style="background-color: rgb(248 216 197);">收飞金额</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td>{{ summary.username }}</td>
                                    <td>{{ summary.xac_transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,71)">{{ summary.xac_transfer_amount_out }}</a></td>
                                    <td>{{ summary.xac_reward_number }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,71)">{{ summary.xac_reward_amount }}</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="Filter.status == 1" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <thead>
                                <tr style="text-align:left;">
                                    <td style="background-color: rgb(248 216 197);">帐号</td>
                                    <td style="background-color: rgb(248 216 197);">笔数</td>
                                    <td style="background-color: rgb(248 216 197);">走飞金额</td>
                                    <td style="background-color: rgb(248 216 197);">走飞退水</td>
                                    <td style="background-color: rgb(248 216 197);">走飞结果</td>
                                    <td style="background-color: rgb(248 216 197);">总结果合计</td>
                                    <td v-if="!TargetIsAdmin" style="background-color: rgb(248 216 197);">上级结果合计</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td v-mark>{{ summary.xac_transfer_username_out }}</td>
                                    <td v-mark>{{ summary.xac_transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,71)">{{ summary.xac_transfer_amount_out }}</a></td>
                                    <td v-mark>{{ summary.xac_transfer_backwater_out }}</td>
                                    <td v-mark>{{ summary.xac_transfer_result_out }}</td>
                                    <td v-mark>{{ summary.xac_total_result }}</td>
                                    <td v-if="!TargetIsAdmin" v-mark>{{ summary.xac_total_up_result }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <br>


                    <!-- xg -->
                    <table id="CreditSixAmount-table-1" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0">
                        <colgroup>
                            <col width="130">
                            <col>
                            <col v-if="Opt.isTeam">
                            <col v-if="Filter.status == 0 && Opt.isTeam">
                            <col>
                            <col>
                            <col v-if="!Opt.isTeam">
                            <col v-if="!Opt.isTeam">
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:center;">
                                <td style="background-color: rgb(152 200 237);" rowspan="2" @click="puls_reduce('xg')"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                    <span id="xg_puls" style="font-size:16px;">-</span>帐号(香港)</div>
                                </td>
                                <td style="background-color: rgb(152 200 237);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">名称</div></td>
                                <td v-if="Opt.isTeam" style="background-color: rgb(152 200 237);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">级别</div></td>
                                <td v-if="Filter.status == 0 && Opt.isTeam" style="background-color: rgb(152 200 237);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">关系</div></td>
                                <td style="background-color: rgb(152 200 237);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下单数</div></td>
                                <td style="background-color: rgb(152 200 237);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下注金额</div></td>
                                <!-- <td v-if="Opt.isTeam" style="background-color: rgb(152 200 237);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">有效金额</div></td> -->
                                <td v-if="!Opt.isTeam" style="background-color: rgb(152 200 237);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">奖金</div></td>
                                <td v-if="!Opt.isTeam" style="background-color: rgb(152 200 237);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">退水</div></td>
                                <td style="background-color: rgb(152 200 237);" rowspan="2">
                                    <div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                        <span v-if="Opt.isTeam">会员结果</span>
                                        <span v-else>结果</span>
                                    </div>
                                </td>
                                <template v-if="Opt.isTeam && Filter.status != 0">
                                    <td style="background-color: rgb(152 200 237);" rowspan="2">{{ isTop ? '代理结果' : '交收' }}</td>
                                    <td v-if="!isTop" style="text-align:center; background-color: rgb(152 200 237);" colspan="4">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                    <td v-if="!isTop" style="text-align:center; background-color: rgb(152 200 237);" colspan="5">代理收飞</td>
                                    <td v-if="isTop" style="background-color: rgb(152 200 237);" rowspan="2">收飞金额</td>
                                    <td v-if="isTop" style="background-color: rgb(152 200 237);" rowspan="2">收飞结果</td>
                                    <td style="background-color: rgb(152 200 237);" rowspan="2">总结果</td>
                                    <td v-if="!isTop && Filter.pid != 1" style="background-color: rgb(152 200 237);" rowspan="2">上级结果</td>
                                    <td style="background-color: rgb(152 200 237);" rowspan="2">贡献</td>
                                    <td v-if="Filter.pid != 1" style="background-color: rgb(152 200 237);" rowspan="2">贡献2</td>
                                    <td style="background-color: rgb(152 200 237);" rowspan="2">贡献比例</td>
                                </template>
                            </tr>
                            <tr v-if="Opt.isTeam && !isTop && Filter.status != 0" style="text-align:center;">
                                <td>退水</td>
                                <td>金额</td>
                                <td>占成</td>
                                <td>结果</td>
                                <td>退水</td>
                                <td>金额</td>
                                <td>输赢</td>
                                <td>占成</td>
                                <td>结果</td>
                            </tr>
                        </thead>
                        <tbody id="xg_data" style="text-align:left;">
                            <tr v-for="(item,index) in main._list" v-show="!!summary && !main._loading" :key="item.id">
                                <template v-if="item.xg_bets_num > 0">
                                    <td style="text-align:left;">
                                        <a class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,1)">{{ item.username }}</a>
                                    </td>
                                    <td style="text-align:left;">
                                        <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                                    </td>
                                    <td v-if="Opt.isTeam">
                                        <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                                    </td>
                                    <td v-if="Filter.status == 0 && Opt.isTeam">
                                        <a class="link" @click="LevelView(item)">关系</a>
                                    </td>
                                    <td style="text-align:left;">{{ item.xg_bets_num }}</td>
                                    <td style="text-align:left;">
                                        <a v-mark class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,1)">{{ item.xg_bets_amount }}</a>
                                    </td>

                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ item.xg_valid_amount }}</td> -->
                                    <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.xg_bets_bonus }}</td>
                                    <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.xg_percent_backwater_amount }}</td>
                                    <td v-mark style="text-align:left;">{{ item.xg_members_result }}</td>
                                    <template v-if="Opt.isTeam && Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ item.xg_js_result }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ Price(item.xg_percent_backwater_amount,{separate: false}) }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xg_percent_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xg_percent }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xg_percent_result }}</td>

                                            <td v-mark style="text-align:left;">{{ Price(item.xg_transfer_backwater,{separate: false}) }}</td>
                                            <td v-mark style="text-align:left;"><a class="link" @click="Edit(item,3)">{{ item.xg_transfer_amount }}</a></td>

                                            <td v-mark style="text-align:left;">{{ item.xg_transfer_wins }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xg_transfer }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xg_transfer_result }}</td>
                                        </template>

                                        <td v-if="isTop" style="text-align:left;"><a v-mark class="link" @click="Edit(item,3)">{{ item.xg_transfer_amount }}</a></td>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ item.xg_transfer_result }}</td>

                                        <td v-mark style="text-align:left;">{{ item.xg_result }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ item.xg_up_result }}</td>
                                        {{/* 奉献额与奉献比率 */}}
                                        <td v-mark style="text-align:left;">{{ item.xg_contribution_one }}</td>
                                        <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ item.xg_contribution_two }}</td>
                                        <td style="text-align:center;">
                                            <div class="l-flex-c o-pr-s">
                                                <el-progress class="PrintHide" style="width:55px;" :percentage="CalcuNumber(item.xg_contribution_proportion)" :show-text="false" />
                                                <p class="l-flex-1 c-text-6 c-text-r">{{ item.xg_contribution_proportion }}%</p>
                                            </div>
                                        </td>
                                    </template>
                                </template>
                            </tr>
                        </tbody>
                        <tfoot>
                            <!-- <tr v-if="main._empty">
                                <td class="empty" colspan="100">暂无数据</td>
                            </tr> -->
                            <template v-if="!main._empty && Opt.isTeam">
                                <tr v-if="main._count >= 50" class="subtotal">
                                    <td style="text-align:left;" colspan="3">小计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('xg_bets_num') }}</td>
                                    <td v-mark style="text-align:left;">{{ CalcuRow('xg_bets_amount') }}</td>
                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ CalcuRow('xg_valid_amount') }}</td> -->
                                    <td v-mark style="text-align:left;">{{ CalcuRow('xg_members_result') }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ CalcuRow('xg_js_result') }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xg_percent_backwater_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xg_percent_amount') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xg_percent_result') }}</td>

                                            <td v-mark style="text-align:left;">{{ CalcuRow('xg_transfer_backwater') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xg_transfer_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xg_transfer_wins') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xg_transfer_result') }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('xg_transfer_amount') }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('xg_transfer_result') }}</td>

                                        <td v-mark style="text-align:left;">{{ CalcuRow('xg_result') }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('xg_up_result') }}</td>
                                    </template>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('xg_contribution_one') }}</td>
                                    <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('xg_contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </tr>
                                <tr v-if="!main._empty && summary" v-show="!main._loading" class="total">
                                    <td style="text-align:left;" colspan="3">合计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ summary.xg_bets_num }}</td>
                                    <td v-mark style="text-align:left;">{{ summary.xg_bets_amount }}</td>
                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ summary.xg_valid_amount }}</td> -->
                                    <td v-mark style="text-align:left;">{{ summary.xg_members_result }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ summary.xg_js_result }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ summary.xg_percent_backwater_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.xg_percent_amount }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.xg_percent_result }}</td>

                                            <td v-mark style="text-align:left;">{{ summary.xg_transfer_backwater }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.xg_transfer_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.xg_transfer_wins }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.xg_transfer_result }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.xg_transfer_amount }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.xg_transfer_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.xg_result }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;" by="上级结果">{{ summary.xg_up_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.xg_contribution_one }}</td>
                                        <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ summary.xg_contribution_two }}</td>
                                        <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                    </template>
                                </tr>
                            </template>
                        </tfoot>
                    </table>
                    <template v-if="Opt.isTeam && summary && summary.xg_transfer_number_out > 0 && !main._loading">
                        <table v-if="Filter.status == 0" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <colgroup>
                            <col width="130">
                            <col width="195">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                            <thead>
                                <tr style="text-align:left;">
                                    <td style="background-color: rgb(152 200 237);">帐号</td>
                                    <td style="background-color: rgb(152 200 237);">走飞笔数</td>
                                    <td style="background-color: rgb(152 200 237);">走飞金额</td>
                                    <td style="background-color: rgb(152 200 237);">收飞笔数</td>
                                    <td style="background-color: rgb(152 200 237);">收飞金额</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td>{{ summary.username }}</td>
                                    <td>{{ summary.xg_transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,31)">{{ summary.xg_transfer_amount_out }}</a></td>
                                    <td>{{ summary.xg_reward_number }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,31)">{{ summary.xg_reward_amount }}</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="Filter.status == 1" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <thead>
                                <tr style="text-align:left;">
                                    <td style="background-color: rgb(152 200 237);">帐号</td>
                                    <td style="background-color: rgb(152 200 237);">笔数</td>
                                    <td style="background-color: rgb(152 200 237);">走飞金额</td>
                                    <td style="background-color: rgb(152 200 237);">走飞退水</td>
                                    <td style="background-color: rgb(152 200 237);">走飞结果</td>
                                    <td style="background-color: rgb(152 200 237);">总结果合计</td>
                                    <td v-if="!TargetIsAdmin" style="background-color: rgb(152 200 237);">上级结果合计</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td v-mark>{{ summary.xg_transfer_username_out }}</td>
                                    <td v-mark>{{ summary.xg_transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,31)">{{ summary.xg_transfer_amount_out }}</a></td>
                                    <td v-mark>{{ summary.xg_transfer_backwater_out }}</td>
                                    <td v-mark>{{ summary.xg_transfer_result_out }}</td>
                                    <td v-mark>{{ summary.xg_total_result }}</td>
                                    <td v-if="!TargetIsAdmin" v-mark>{{ summary.xg_total_up_result }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <br>
                    <!-- ac -->
                    <table id="CreditSixAmount-table-2" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0">
                        <colgroup>
                            <col width="130">
                            <col>
                            <col v-if="Opt.isTeam">
                            <col v-if="Filter.status == 0 && Opt.isTeam">
                            <col>
                            <col>
                            <col v-if="!Opt.isTeam">
                            <col v-if="!Opt.isTeam">
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:center;">
                                <td style="background-color: rgb(190 206 239);" rowspan="2" @click="puls_reduce('ac')"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                    <span id="ac_puls" style="font-size:16px;">-</span>帐号(旧澳门)</div>
                                </td>
                                <td style="background-color: rgb(190 206 239);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">名称</div></td>
                                <td v-if="Opt.isTeam" style="background-color: rgb(190 206 239);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">级别</div></td>
                                <td v-if="Filter.status == 0 && Opt.isTeam" style="background-color: rgb(190 206 239);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">关系</div></td>
                                <td style="background-color: rgb(190 206 239);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下单数</div></td>
                                <td style="background-color: rgb(190 206 239);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下注金额</div></td>
                                <!-- <td v-if="Opt.isTeam" style="background-color: rgb(190 206 239);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">有效金额</div></td> -->
                                <td v-if="!Opt.isTeam" style="background-color: rgb(190 206 239);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">奖金</div></td>
                                <td v-if="!Opt.isTeam" style="background-color: rgb(190 206 239);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">退水</div></td>
                                <td style="background-color: rgb(190 206 239);" rowspan="2">
                                    <div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                        <span v-if="Opt.isTeam">会员结果</span>
                                        <span v-else>结果</span>
                                    </div>
                                </td>
                                <template v-if="Opt.isTeam && Filter.status != 0">
                                    <td style="background-color: rgb(190 206 239);" rowspan="2">{{ isTop ? '代理结果' : '交收' }}</td>
                                    <td v-if="!isTop" style="text-align:center; background-color: rgb(190 206 239);" colspan="4">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                    <td v-if="!isTop" style="text-align:center; background-color: rgb(190 206 239);" colspan="5">代理收飞</td>
                                    <td v-if="isTop" style="background-color: rgb(190 206 239);" rowspan="2">收飞金额</td>
                                    <td v-if="isTop" style="background-color: rgb(190 206 239);" rowspan="2">收飞结果</td>
                                    <td style="background-color: rgb(190 206 239);" rowspan="2">总结果</td>
                                    <td v-if="!isTop && Filter.pid != 1" style="background-color: rgb(190 206 239);" rowspan="2">上级结果</td>
                                    <td style="background-color: rgb(190 206 239);" rowspan="2">贡献</td>
                                    <td v-if="Filter.pid != 1" style="background-color: rgb(190 206 239);" rowspan="2">贡献2</td>
                                    <td style="background-color: rgb(190 206 239);" rowspan="2">贡献比例</td>
                                </template>
                            </tr>
                            <tr v-if="Opt.isTeam && !isTop && Filter.status != 0" style="text-align:center;">
                                <td>退水</td>
                                <td>金额</td>
                                <td>占成</td>
                                <td>结果</td>
                                <td>退水</td>
                                <td>金额</td>
                                <td>输赢</td>
                                <td>占成</td>
                                <td>结果</td>
                            </tr>
                        </thead>
                        <tbody id="ac_data" style="text-align:left;">
                            <tr v-for="(item,index) in main._list" v-show="!!summary && !main._loading" :key="item.id">
                                <template v-if="item.ac_bets_num > 0">
                                    <td style="text-align:left;">
                                        <a class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,2)">{{ item.username }}</a>
                                    </td>
                                    <td style="text-align:left;">
                                        <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                                    </td>
                                    <td v-if="Opt.isTeam">
                                        <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                                    </td>
                                    <td v-if="Filter.status == 0 && Opt.isTeam">
                                        <a class="link" @click="LevelView(item)">关系</a>
                                    </td>
                                    <td style="text-align:left;">{{ item.ac_bets_num }}</td>
                                    <td style="text-align:left;">
                                        <a v-mark class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,2)">{{ item.ac_bets_amount }}</a>
                                    </td>

                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ item.ac_valid_amount }}</td> -->
                                    <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.ac_bets_bonus }}</td>
                                    <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.ac_percent_backwater_amount }}</td>
                                    <td v-mark style="text-align:left;">{{ item.ac_members_result }}</td>
                                    <template v-if="Opt.isTeam && Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ item.ac_js_result }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ Price(item.ac_percent_backwater_amount,{separate: false}) }}</td>
                                            <td v-mark style="text-align:left;">{{ item.ac_percent_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ item.ac_percent }}</td>
                                            <td v-mark style="text-align:left;">{{ item.ac_percent_result }}</td>

                                            <td v-mark style="text-align:left;">{{ Price(item.ac_transfer_backwater,{separate: false}) }}</td>
                                            <td v-mark style="text-align:left;"><a class="link" @click="Edit(item,4)">{{ item.ac_transfer_amount }}</a></td>

                                            <td v-mark style="text-align:left;">{{ item.ac_transfer_wins }}</td>
                                            <td v-mark style="text-align:left;">{{ item.ac_transfer }}</td>
                                            <td v-mark style="text-align:left;">{{ item.ac_transfer_result }}</td>
                                        </template>

                                        <td v-if="isTop" style="text-align:left;"><a v-mark class="link" @click="Edit(item,4)">{{ item.ac_transfer_amount }}</a></td>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ item.ac_transfer_result }}</td>

                                        <td v-mark style="text-align:left;">{{ item.ac_result }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ item.ac_up_result }}</td>
                                        {{/* 奉献额与奉献比率 */}}
                                        <td v-mark style="text-align:left;">{{ item.ac_contribution_one }}</td>
                                        <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ item.ac_contribution_two }}</td>
                                        <td style="text-align:center;">
                                            <div class="l-flex-c o-pr-s">
                                                <el-progress class="PrintHide" style="width:55px;" :percentage="CalcuNumber(item.ac_contribution_proportion)" :show-text="false" />
                                                <p class="l-flex-1 c-text-6 c-text-r">{{ item.ac_contribution_proportion }}%</p>
                                            </div>
                                        </td>
                                    </template>
                                </template>
                            </tr>
                        </tbody>
                        <tfoot>
                            <!-- <tr v-if="main._empty">
                                <td class="empty" colspan="100">暂无数据</td>
                            </tr> -->
                            <template v-if="!main._empty && Opt.isTeam">
                                <tr v-if="main._count >= 50" class="subtotal">
                                    <td style="text-align:left;" colspan="3">小计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('ac_bets_num') }}</td>
                                    <td v-mark style="text-align:left;">{{ CalcuRow('ac_bets_amount') }}</td>
                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ CalcuRow('ac_valid_amount') }}</td> -->
                                    <td v-mark style="text-align:left;">{{ CalcuRow('ac_members_result') }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ CalcuRow('ac_js_result') }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ CalcuRow('ac_percent_backwater_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('ac_percent_amount') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('ac_percent_result') }}</td>

                                            <td v-mark style="text-align:left;">{{ CalcuRow('ac_transfer_backwater') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('ac_transfer_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('ac_transfer_wins') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('ac_transfer_result') }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('ac_transfer_amount') }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('ac_transfer_result') }}</td>

                                        <td v-mark style="text-align:left;">{{ CalcuRow('ac_result') }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('ac_up_result') }}</td>
                                    </template>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('ac_contribution_one') }}</td>
                                    <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('ac_contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </tr>
                                <tr v-if="!main._empty && summary" v-show="!main._loading" class="total">
                                    <td style="text-align:left;" colspan="3">合计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ summary.ac_bets_num }}</td>
                                    <td v-mark style="text-align:left;">{{ summary.ac_bets_amount }}</td>
                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ summary.ac_valid_amount }}</td> -->
                                    <td v-mark style="text-align:left;">{{ summary.ac_members_result }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ summary.ac_js_result }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ summary.ac_percent_backwater_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.ac_percent_amount }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.ac_percent_result }}</td>

                                            <td v-mark style="text-align:left;">{{ summary.ac_transfer_backwater }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.ac_transfer_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.ac_transfer_wins }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.ac_transfer_result }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.ac_transfer_amount }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.ac_transfer_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.ac_result }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;" by="上级结果">{{ summary.ac_up_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.ac_contribution_one }}</td>
                                        <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ summary.ac_contribution_two }}</td>
                                        <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                    </template>
                                </tr>
                            </template>
                        </tfoot>
                    </table>
                    <template v-if="Opt.isTeam && summary && summary.ac_transfer_number_out > 0 && !main._loading">
                        <table v-if="Filter.status == 0" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <colgroup>
                            <col width="130">
                            <col width="195">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                            <thead>
                                <tr style="text-align:left;">
                                    <td style="background-color: rgb(190 206 239);">帐号</td>
                                    <td style="background-color: rgb(190 206 239);">走飞笔数</td>
                                    <td style="background-color: rgb(190 206 239);">走飞金额</td>
                                    <td style="background-color: rgb(190 206 239);">收飞笔数</td>
                                    <td style="background-color: rgb(190 206 239);">收飞金额</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td>{{ summary.username }}</td>
                                    <td>{{ summary.ac_transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,41)">{{ summary.ac_transfer_amount_out }}</a></td>
                                    <td>{{ summary.ac_reward_number }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,41)">{{ summary.ac_reward_amount }}</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="Filter.status == 1" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <thead>
                                <tr style="text-align:left;">
                                    <td style="background-color: rgb(190 206 239);">帐号</td>
                                    <td style="background-color: rgb(190 206 239);">笔数</td>
                                    <td style="background-color: rgb(190 206 239);">走飞金额</td>
                                    <td style="background-color: rgb(190 206 239);">走飞退水</td>
                                    <td style="background-color: rgb(190 206 239);">走飞结果</td>
                                    <td style="background-color: rgb(190 206 239);">总结果合计</td>
                                    <td v-if="!TargetIsAdmin" style="background-color: rgb(190 206 239);">上级结果合计</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td v-mark>{{ summary.ac_transfer_username_out }}</td>
                                    <td v-mark>{{ summary.ac_transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,41)">{{ summary.ac_transfer_amount_out }}</a></td>
                                    <td v-mark>{{ summary.ac_transfer_backwater_out }}</td>
                                    <td v-mark>{{ summary.ac_transfer_result_out }}</td>
                                    <td v-mark>{{ summary.ac_total_result }}</td>
                                    <td v-if="!TargetIsAdmin" v-mark>{{ summary.ac_total_up_result }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <br>
                    
                    <!-- tw -->
                    <table id="CreditSixAmount-table-4" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0">
                        <colgroup>
                            <col width="130">
                            <col>
                            <col v-if="Opt.isTeam">
                            <col v-if="Filter.status == 0 && Opt.isTeam">
                            <col>
                            <col>
                            <col v-if="!Opt.isTeam">
                            <col v-if="!Opt.isTeam">
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:center;">
                                <td style="background-color: rgb(183 231 189);" rowspan="2" @click="puls_reduce('tw')"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                    <span id="tw_puls" style="font-size:16px;">-</span>帐号(快乐8)</div>
                                </td>
                                <td style="background-color: rgb(183 231 189);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">名称</div></td>
                                <td v-if="Opt.isTeam" style="background-color: rgb(183 231 189);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">级别</div></td>
                                <td v-if="Filter.status == 0 && Opt.isTeam" style="background-color: rgb(183 231 189);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">关系</div></td>
                                <td style="background-color: rgb(183 231 189);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下单数</div></td>
                                <td style="background-color: rgb(183 231 189);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下注金额</div></td>
                                <td v-if="!Opt.isTeam" style="background-color: rgb(183 231 189);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">奖金</div></td>
                                <td v-if="!Opt.isTeam" style="background-color: rgb(183 231 189);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">退水</div></td>
                                <td style="background-color: rgb(183 231 189);" rowspan="2">
                                    <div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                        <span v-if="Opt.isTeam">会员结果</span>
                                        <span v-else>结果</span>
                                    </div>
                                </td>
                                <template v-if="Opt.isTeam && Filter.status != 0">
                                    <td style="background-color: rgb(183 231 189);" rowspan="2">{{ isTop ? '代理结果' : '交收' }}</td>
                                    <td v-if="!isTop" style="text-align:center; background-color: rgb(183 231 189);" colspan="4">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                    <td v-if="!isTop" style="text-align:center; background-color: rgb(183 231 189);" colspan="5">代理收飞</td>
                                    <td v-if="isTop" style="background-color: rgb(183 231 189);" rowspan="2">收飞金额</td>
                                    <td v-if="isTop" style="background-color: rgb(183 231 189);" rowspan="2">收飞结果</td>
                                    <td style="background-color: rgb(183 231 189);" rowspan="2">总结果</td>
                                    <td v-if="!isTop && Filter.pid != 1" style="background-color: rgb(183 231 189);" rowspan="2">上级结果</td>
                                    <td style="background-color: rgb(183 231 189);" rowspan="2">贡献</td>
                                    <td v-if="Filter.pid != 1" style="background-color: rgb(183 231 189);" rowspan="2">贡献2</td>
                                    <td style="background-color: rgb(183 231 189);" rowspan="2">贡献比例</td>
                                </template>
                            </tr>
                            <tr v-if="Opt.isTeam && !isTop && Filter.status != 0" style="text-align:center;">
                                <td>退水</td>
                                <td>金额</td>
                                <td>占成</td>
                                <td>结果</td>
                                <td>退水</td>
                                <td>金额</td>
                                <td>输赢</td>
                                <td>占成</td>
                                <td>结果</td>
                            </tr>
                        </thead>
                        <tbody id="tw_data" style="text-align:left;">
                            <tr v-for="(item,index) in main._list" v-show="!!summary && !main._loading" :key="item.id">
                                <template v-if="item.tw_bets_num > 0">
                                    <td style="text-align:left;">
                                        <a class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,3)">{{ item.username }}</a>
                                    </td>
                                    <td style="text-align:left;">
                                        <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                                    </td>
                                    <td v-if="Opt.isTeam">
                                        <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                                    </td>
                                    <td v-if="Filter.status == 0 && Opt.isTeam">
                                        <a class="link" @click="LevelView(item)">关系</a>
                                    </td>
                                    <td style="text-align:left;">{{ item.tw_bets_num }}</td>
                                    <td style="text-align:left;">
                                        <a v-mark class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,3)">{{ item.tw_bets_amount }}</a>
                                    </td>
                                    <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.tw_bets_bonus }}</td>
                                    <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.tw_percent_backwater_amount }}</td>
                                    <td v-mark style="text-align:left;">{{ item.tw_members_result }}</td>
                                    <template v-if="Opt.isTeam && Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ item.tw_js_result }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ Price(item.tw_percent_backwater_amount,{separate: false}) }}</td>
                                            <td v-mark style="text-align:left;">{{ item.tw_percent_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ item.tw_percent }}</td>
                                            <td v-mark style="text-align:left;">{{ item.tw_percent_result }}</td>

                                            <td v-mark style="text-align:left;">{{ Price(item.tw_transfer_backwater,{separate: false}) }}</td>
                                            <td v-mark style="text-align:left;"><a class="link" @click="Edit(item,5)">{{ item.tw_transfer_amount }}</a></td>

                                            <td v-mark style="text-align:left;">{{ item.tw_transfer_wins }}</td>
                                            <td v-mark style="text-align:left;">{{ item.tw_transfer }}</td>
                                            <td v-mark style="text-align:left;">{{ item.tw_transfer_result }}</td>
                                        </template>

                                        <td v-if="isTop" style="text-align:left;"><a v-mark class="link" @click="Edit(item,5)">{{ item.tw_transfer_amount }}</a></td>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ item.tw_transfer_result }}</td>

                                        <td v-mark style="text-align:left;">{{ item.tw_result }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ item.tw_up_result }}</td>
                                        {{/* 奉献额与奉献比率 */}}
                                        <td v-mark style="text-align:left;">{{ item.tw_contribution_one }}</td>
                                        <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ item.tw_contribution_two }}</td>
                                        <td style="text-align:center;">
                                            <div class="l-flex-c o-pr-s">
                                                <el-progress class="PrintHide" style="width:55px;" :percentage="CalcuNumber(item.tw_contribution_proportion)" :show-text="false" />
                                                <p class="l-flex-1 c-text-6 c-text-r">{{ item.tw_contribution_proportion }}%</p>
                                            </div>
                                        </td>
                                    </template>
                                </template>
                            </tr>
                        </tbody>
                        <tfoot>
                            <template v-if="!main._empty && Opt.isTeam">
                                <tr v-if="main._count >= 50" class="subtotal">
                                    <td style="text-align:left;" colspan="3">小计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('tw_bets_num') }}</td>
                                    <td v-mark style="text-align:left;">{{ CalcuRow('tw_bets_amount') }}</td>
                                    <td v-mark style="text-align:left;">{{ CalcuRow('tw_members_result') }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ CalcuRow('tw_js_result') }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ CalcuRow('tw_percent_backwater_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('tw_percent_amount') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('tw_percent_result') }}</td>

                                            <td v-mark style="text-align:left;">{{ CalcuRow('tw_transfer_backwater') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('tw_transfer_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('tw_transfer_wins') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('tw_transfer_result') }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('tw_transfer_amount') }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('tw_transfer_result') }}</td>

                                        <td v-mark style="text-align:left;">{{ CalcuRow('tw_result') }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('tw_up_result') }}</td>
                                    </template>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('tw_contribution_one') }}</td>
                                    <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('tw_contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </tr>
                                <tr v-if="!main._empty && summary" v-show="!main._loading" class="total">
                                    <td style="text-align:left;" colspan="3">合计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ summary.tw_bets_num }}</td>
                                    <td v-mark style="text-align:left;">{{ summary.tw_bets_amount }}</td>
                                    <td v-mark style="text-align:left;">{{ summary.tw_members_result }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ summary.tw_js_result }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ summary.tw_percent_backwater_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.tw_percent_amount }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.tw_percent_result }}</td>

                                            <td v-mark style="text-align:left;">{{ summary.tw_transfer_backwater }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.tw_transfer_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.tw_transfer_wins }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.tw_transfer_result }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.tw_transfer_amount }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.tw_transfer_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.tw_result }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;" by="上级结果">{{ summary.tw_up_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.tw_contribution_one }}</td>
                                        <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ summary.tw_contribution_two }}</td>
                                        <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                    </template>
                                </tr>
                            </template>
                        </tfoot>
                    </table>
                    <template v-if="Opt.isTeam && summary && summary.tw_transfer_number_out > 0 && !main._loading">
                        <table v-if="Filter.status == 0" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <colgroup>
                            <col width="130">
                            <col width="195">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                            <thead>
                                <tr style="text-align:left;">
                                    <td style="background-color: rgb(183 231 189);">帐号</td>
                                    <td style="background-color: rgb(183 231 189);">走飞笔数</td>
                                    <td style="background-color: rgb(183 231 189);">走飞金额</td>
                                    <td style="background-color: rgb(183 231 189);">收飞笔数</td>
                                    <td style="background-color: rgb(183 231 189);">收飞金额</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td>{{ summary.username }}</td>
                                    <td>{{ summary.tw_transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,51)">{{ summary.tw_transfer_amount_out }}</a></td>
                                    <td>{{ summary.tw_reward_number }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,51)">{{ summary.tw_reward_amount }}</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="Filter.status == 1" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <thead>
                                <tr style="text-align:left;">
                                    <td style="background-color: rgb(183 231 189);">帐号</td>
                                    <td style="background-color: rgb(183 231 189);">笔数</td>
                                    <td style="background-color: rgb(183 231 189);">走飞金额</td>
                                    <td style="background-color: rgb(183 231 189);">走飞退水</td>
                                    <td style="background-color: rgb(183 231 189);">走飞结果</td>
                                    <td style="background-color: rgb(183 231 189);">总结果合计</td>
                                    <td v-if="!TargetIsAdmin" style="background-color: rgb(183 231 189);">上级结果合计</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td v-mark>{{ summary.tw_transfer_username_out }}</td>
                                    <td v-mark>{{ summary.tw_transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,51)">{{ summary.tw_transfer_amount_out }}</a></td>
                                    <td v-mark>{{ summary.tw_transfer_backwater_out }}</td>
                                    <td v-mark>{{ summary.tw_transfer_result_out }}</td>
                                    <td v-mark>{{ summary.tw_total_result }}</td>
                                    <td v-if="!TargetIsAdmin" v-mark>{{ summary.tw_total_up_result }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <br>
                    
                     <!-- xjp -->
                    <!-- <table id="CreditSixAmount-table-4" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0">
                        <colgroup>
                            <col width="130">
                            <col>
                            <col v-if="Opt.isTeam">
                            <col v-if="Filter.status == 0 && Opt.isTeam">
                            <col>
                            <col>
                            <col v-if="!Opt.isTeam">
                            <col v-if="!Opt.isTeam">
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:center;">
                                <td style="background-color: rgb(245 208 220);" rowspan="2" @click="puls_reduce('xjp')"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                    <span id="xjp_puls" style="font-size:16px;">-</span>帐号(新加坡)</div>
                                </td>
                                <td style="background-color: rgb(245 208 220);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">名称</div></td>
                                <td v-if="Opt.isTeam" style="background-color: rgb(245 208 220);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">级别</div></td>
                                <td v-if="Filter.status == 0 && Opt.isTeam" style="background-color: rgb(245 208 220);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">关系</div></td>
                                <td style="background-color: rgb(245 208 220);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下单数</div></td>
                                <td style="background-color: rgb(245 208 220);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下注金额</div></td>
                                <td v-if="!Opt.isTeam" style="background-color: rgb(245 208 220);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">奖金</div></td>
                                <td v-if="!Opt.isTeam" style="background-color: rgb(245 208 220);" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">退水</div></td>
                                <td style="background-color: rgb(245 208 220);" rowspan="2">
                                    <div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                        <span v-if="Opt.isTeam">会员结果</span>
                                        <span v-else>结果</span>
                                    </div>
                                </td>
                                <template v-if="Opt.isTeam && Filter.status != 0">
                                    <td style="background-color: rgb(245 208 220);" rowspan="2">{{ isTop ? '代理结果' : '交收' }}</td>
                                    <td v-if="!isTop" style="text-align:center; background-color: rgb(245 208 220);" colspan="4">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                    <td v-if="!isTop" style="text-align:center; background-color: rgb(245 208 220);" colspan="5">代理收飞</td>
                                    <td v-if="isTop" style="background-color: rgb(245 208 220);" rowspan="2">收飞金额</td>
                                    <td v-if="isTop" style="background-color: rgb(245 208 220);" rowspan="2">收飞结果</td>
                                    <td style="background-color: rgb(245 208 220);" rowspan="2">总结果</td>
                                    <td v-if="!isTop && Filter.pid != 1" style="background-color: rgb(245 208 220);" rowspan="2">上级结果</td>
                                    <td style="background-color: rgb(245 208 220);" rowspan="2">贡献</td>
                                    <td v-if="Filter.pid != 1" style="background-color: rgb(245 208 220);" rowspan="2">贡献2</td>
                                    <td style="background-color: rgb(245 208 220);" rowspan="2">贡献比例</td>
                                </template>
                            </tr>
                            <tr v-if="Opt.isTeam && !isTop && Filter.status != 0" style="text-align:center;">
                                <td>退水</td>
                                <td>金额</td>
                                <td>占成</td>
                                <td>结果</td>
                                <td>退水</td>
                                <td>金额</td>
                                <td>输赢</td>
                                <td>占成</td>
                                <td>结果</td>
                            </tr>
                        </thead>
                        <tbody id="xjp_data" style="text-align:left;">
                            <tr v-for="(item,index) in main._list" v-show="!!summary && !main._loading" :key="item.id">
                                <template v-if="item.xjp_bets_num > 0">
                                    <td style="text-align:left;">
                                        <a class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,4)">{{ item.username }}</a>
                                    </td>
                                    <td style="text-align:left;">
                                        <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                                    </td>
                                    <td v-if="Opt.isTeam">
                                        <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                                    </td>
                                    <td v-if="Filter.status == 0 && Opt.isTeam">
                                        <a class="link" @click="LevelView(item)">关系</a>
                                    </td>
                                    <td style="text-align:left;">{{ item.xjp_bets_num }}</td>
                                    <td style="text-align:left;">
                                        <a v-mark class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,4)">{{ item.xjp_bets_amount }}</a>
                                    </td>

                                    <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.xjp_bets_bonus }}</td>
                                    <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.xjp_percent_backwater_amount }}</td>
                                    <td v-mark style="text-align:left;">{{ item.xjp_members_result }}</td>
                                    <template v-if="Opt.isTeam && Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ item.xjp_js_result }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ Price(item.xjp_percent_backwater_amount,{separate: false}) }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xjp_percent_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xjp_percent }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xjp_percent_result }}</td>

                                            <td v-mark style="text-align:left;">{{ Price(item.xjp_transfer_backwater,{separate: false}) }}</td>
                                            <td v-mark style="text-align:left;"><a class="link" @click="Edit(item,6)">{{ item.xjp_transfer_amount }}</a></td>
                                            <td v-mark style="text-align:left;">{{ item.xjp_transfer_wins }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xjp_transfer }}</td>
                                            <td v-mark style="text-align:left;">{{ item.xjp_transfer_result }}</td>
                                        </template>

                                        <td v-if="isTop" style="text-align:left;"><a v-mark class="link" @click="Edit(item,6)">{{ item.xjp_transfer_amount }}</a></td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ item.xjp_transfer_result }}</td>

                                        <td v-mark style="text-align:left;">{{ item.xjp_result }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ item.xjp_up_result }}</td>
                                    
                                        <td v-mark style="text-align:left;">{{ item.xjp_contribution_one }}</td>
                                        <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ item.xjp_contribution_two }}</td>
                                        <td style="text-align:center;">
                                            <div class="l-flex-c o-pr-s">
                                                <el-progress class="PrintHide" style="width:55px;" :percentage="CalcuNumber(item.xjp_contribution_proportion)" :show-text="false" />
                                                <p class="l-flex-1 c-text-6 c-text-r">{{ item.xjp_contribution_proportion }}%</p>
                                            </div>
                                        </td>
                                    </template>
                                </template>
                            </tr>
                        </tbody>
                        <tfoot>
                           
                            <template v-if="!main._empty && Opt.isTeam">
                                <tr v-if="main._count >= 50" class="subtotal">
                                    <td style="text-align:left;" colspan="3">小计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('xjp_bets_num') }}</td>
                                    <td v-mark style="text-align:left;">{{ CalcuRow('xjp_bets_amount') }}</td>
                                    <td v-mark style="text-align:left;">{{ CalcuRow('xjp_members_result') }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ CalcuRow('xjp_js_result') }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xjp_percent_backwater_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xjp_percent_amount') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xjp_percent_result') }}</td>

                                            <td v-mark style="text-align:left;">{{ CalcuRow('xjp_transfer_backwater') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xjp_transfer_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xjp_transfer_wins') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('xjp_transfer_result') }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('xjp_transfer_amount') }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('xjp_transfer_result') }}</td>

                                        <td v-mark style="text-align:left;">{{ CalcuRow('xjp_result') }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('xjp_up_result') }}</td>
                                    </template>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('xjp_contribution_one') }}</td>
                                    <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('xjp_contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </tr>
                                <tr v-if="!main._empty && summary" v-show="!main._loading" class="total">
                                    <td style="text-align:left;" colspan="3">合计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ summary.xjp_bets_num }}</td>
                                    <td v-mark style="text-align:left;">{{ summary.xjp_bets_amount }}</td>
                                    <td v-mark style="text-align:left;">{{ summary.xjp_members_result }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ summary.xjp_js_result }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ summary.xjp_percent_backwater_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.xjp_percent_amount }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.xjp_percent_result }}</td>

                                            <td v-mark style="text-align:left;">{{ summary.xjp_transfer_backwater }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.xjp_transfer_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.xjp_transfer_wins }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.xjp_transfer_result }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.xjp_transfer_amount }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.xjp_transfer_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.xjp_result }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;" by="上级结果">{{ summary.xjp_up_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.xjp_contribution_one }}</td>
                                        <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ summary.xjp_contribution_two }}</td>
                                        <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                    </template>
                                </tr>
                            </template>
                        </tfoot>
                    </table>
                    <template v-if="Opt.isTeam && summary && summary.xjp_transfer_number_out > 0 && !main._loading">
                        <table v-if="Filter.status == 0" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <colgroup>
                            <col width="130">
                            <col width="195">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                            <thead>
                                <tr style="text-align:left;">
                                    <td style="background-color: rgb(245 208 220);">帐号</td>
                                    <td style="background-color: rgb(245 208 220);">走飞笔数</td>
                                    <td style="background-color: rgb(245 208 220);">走飞金额</td>
                                    <td style="background-color: rgb(245 208 220);">收飞笔数</td>
                                    <td style="background-color: rgb(245 208 220);">收飞金额</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td>{{ summary.username }}</td>
                                    <td>{{ summary.xjp_transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,61)">{{ summary.xjp_transfer_amount_out }}</a></td>
                                    <td>{{ summary.xjp_reward_number }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,61)">{{ summary.xjp_reward_amount }}</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="Filter.status == 1" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <thead>
                                <tr style="text-align:left;">
                                    <td style="background-color: rgb(245 208 220);">帐号</td>
                                    <td style="background-color: rgb(245 208 220);">笔数</td>
                                    <td style="background-color: rgb(245 208 220);">走飞金额</td>
                                    <td style="background-color: rgb(245 208 220);">走飞退水</td>
                                    <td style="background-color: rgb(245 208 220);">走飞结果</td>
                                    <td style="background-color: rgb(245 208 220);">总结果合计</td>
                                    <td v-if="!TargetIsAdmin" style="background-color: rgb(245 208 220);">上级结果合计</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td v-mark>{{ summary.xjp_transfer_username_out }}</td>
                                    <td v-mark>{{ summary.xjp_transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,61)">{{ summary.xjp_transfer_amount_out }}</a></td>
                                    <td v-mark>{{ summary.xjp_transfer_backwater_out }}</td>
                                    <td v-mark>{{ summary.xjp_transfer_result_out }}</td>
                                    <td v-mark>{{ summary.xjp_total_result }}</td>
                                    <td v-if="!TargetIsAdmin" v-mark>{{ summary.xjp_total_up_result }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template> -->
                 
                    <!-- 综合 -->
                    <table id="CreditSixAmount-table-3" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0">
                        <colgroup>
                            <col width="130">
                            <col>
                            <col v-if="Opt.isTeam">
                            <col v-if="Filter.status == 0 && Opt.isTeam">
                            <col>
                            <col>
                            <col v-if="!Opt.isTeam">
                            <col v-if="!Opt.isTeam">
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:center;">
                                <td rowspan="2" @click="puls_reduce('zh')"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                    <span id="zh_puls" style="font-size:16px;">-</span>帐号(综合)</div>
                                </td>
                                <td rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">名称</div></td>
                                <td v-if="Opt.isTeam" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">级别</div></td>
                                <td v-if="Filter.status == 0 && Opt.isTeam" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">关系</div></td>
                                <td rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下单数</div></td>
                                <td rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下注金额</div></td>
                                <!-- <td v-if="Opt.isTeam" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">有效金额</div></td> -->
                                <td v-if="!Opt.isTeam" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">奖金</div></td>
                                <td v-if="!Opt.isTeam" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">退水</div></td>
                                <td rowspan="2">
                                    <div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                        <span v-if="Opt.isTeam">会员结果</span>
                                        <span v-else>结果</span>
                                    </div>
                                </td>
                                <template v-if="Opt.isTeam && Filter.status != 0">
                                    <td rowspan="2">{{ isTop ? '代理结果' : '交收' }}</td>
                                    <td v-if="!isTop" style="text-align:center;" colspan="4">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                    <td v-if="!isTop" style="text-align:center;" colspan="5">代理收飞</td>
                                    <td v-if="isTop" rowspan="2">收飞金额</td>
                                    <td v-if="isTop" rowspan="2">收飞结果</td>
                                    <td rowspan="2">总结果</td>
                                    <td v-if="!isTop && Filter.pid != 1" rowspan="2">上级结果</td>
                                    <td rowspan="2">贡献</td>
                                    <td v-if="Filter.pid != 1" rowspan="2">贡献2</td>
                                    <td rowspan="2">贡献比例</td>
                                </template>
                            </tr>
                            <tr v-if="Opt.isTeam && !isTop && Filter.status != 0" style="text-align:center;">
                                <td>退水</td>
                                <td>金额</td>
                                <td>占成</td>
                                <td>结果</td>
                                <td>退水</td>
                                <td>金额</td>
                                <td>输赢</td>
                                <td>占成</td>
                                <td>结果</td>
                            </tr>
                        </thead>
                        <tbody id="zh_data" style="text-align:left;">
                            <tr v-for="(item,index) in main._list" v-show="!!summary && !main._loading" :key="item.id">
                                <td style="text-align:left;">
                                    <a class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,0)">{{ item.username }}</a>
                                </td>
                                <td style="text-align:left;">
                                    <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                                </td>
                                <td v-if="Opt.isTeam">
                                    <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                                </td>
                                <td v-if="Filter.status == 0 && Opt.isTeam">
                                    <a class="link" @click="LevelView(item)">关系</a>
                                </td>
                                <td style="text-align:left;">{{ item.bets_num }}</td>
                                <td style="text-align:left;">
                                    <a v-mark class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,0)">{{ item.bets_amount }}</a>
                                </td>

                                <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ item.valid_amount }}</td> -->
                                <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.bets_bonus }}</td>
                                <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.percent_backwater_amount }}</td>
                                <td v-mark style="text-align:left;">{{ item.members_result }}</td>
                                <template v-if="Opt.isTeam && Filter.status != 0">
                                    <td v-mark style="text-align:left;">{{ item.js_result }}</td>

                                    <template v-if="!isTop">
                                        <td v-mark style="text-align:left;">{{ Price(item.percent_backwater_amount,{separate: false}) }}</td>
                                        <td v-mark style="text-align:left;">{{ item.percent_amount }}</td>
                                        <td v-mark style="text-align:left;">{{ item.percent }}</td>
                                        <td v-mark style="text-align:left;">{{ item.percent_result }}</td>

                                        <td v-mark style="text-align:left;">{{ Price(item.transfer_backwater,{separate: false}) }}</td>
                                        <td v-mark style="text-align:left;"><a class="link" @click="Edit(item,1)">{{ item.transfer_amount }}</a></td>
                                        <td v-mark style="text-align:left;">{{ item.transfer_wins }}</td>
                                        <td v-mark style="text-align:left;">{{ item.transfer }}</td>
                                        <td v-mark style="text-align:left;">{{ item.transfer_result }}</td>
                                    </template>

                                    <td v-if="isTop" style="text-align:left;"><a v-mark class="link" @click="Edit(item,1)">{{ item.transfer_amount }}</a></td>
                                    <td v-if="isTop" v-mark style="text-align:left;">{{ item.transfer_result }}</td>

                                    <td v-mark style="text-align:left;">{{ item.result }}</td>
                                    <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ item.up_result }}</td>
                                    {{/* 奉献额与奉献比率 */}}
                                    <td v-mark style="text-align:left;">{{ item.contribution_one }}</td>
                                    <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ item.contribution_two }}</td>
                                    <td style="text-align:center;">
                                        <div class="l-flex-c o-pr-s">
                                            <el-progress class="PrintHide" style="width:55px;" :percentage="CalcuNumber(item.contribution_proportion)" :show-text="false" />
                                            <p class="l-flex-1 c-text-6 c-text-r">{{ item.contribution_proportion }}%</p>
                                        </div>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                        <tfoot>
                            <!-- <tr v-if="main._empty">
                                <td class="empty" colspan="100">暂无数据</td>
                            </tr> -->
                            <template v-if="!main._empty && Opt.isTeam">
                                <tr v-if="main._count >= 50" class="subtotal">
                                    <td style="text-align:left;" colspan="3">小计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('bets_num') }}</td>
                                    <td v-mark style="text-align:left;">{{ CalcuRow('bets_amount') }}</td>
                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ CalcuRow('valid_amount') }}</td> -->
                                    <td v-mark style="text-align:left;">{{ CalcuRow('members_result') }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ CalcuRow('js_result') }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ CalcuRow('percent_backwater_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('percent_amount') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('percent_result') }}</td>

                                            <td v-mark style="text-align:left;">{{ CalcuRow('transfer_backwater') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('transfer_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('transfer_wins') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('transfer_result') }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('transfer_amount') }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('transfer_result') }}</td>

                                        <td v-mark style="text-align:left;">{{ CalcuRow('result') }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('up_result') }}</td>
                                    </template>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('contribution_one') }}</td>
                                    <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </tr>
                                <tr v-if="!main._empty && summary" v-show="!main._loading" class="total">
                                    <td style="text-align:left;" colspan="3">合计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ summary.bets_num }}</td>
                                    <td v-mark style="text-align:left;">{{ summary.bets_amount }}</td>
                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ summary.valid_amount }}</td> -->
                                    <td v-mark style="text-align:left;">{{ summary.members_result }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ summary.js_result }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ summary.percent_backwater_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.percent_amount }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.percent_result }}</td>

                                            <td v-mark style="text-align:left;">{{ summary.transfer_backwater }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.transfer_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.transfer_wins }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.transfer_result }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.transfer_amount }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.transfer_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.result }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;" by="上级结果">{{ summary.up_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.contribution_one }}</td>
                                        <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ summary.contribution_two }}</td>
                                        <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                    </template>
                                </tr>
                            </template>
                        </tfoot>
                    </table>
                    <template v-if="Opt.isTeam && summary && summary.transfer_number_out > 0 && !main._loading">
                        <table v-if="Filter.status == 0" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <colgroup>
                            <col width="130">
                            <col width="195">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                            <thead>
                                <tr style="text-align:left;">
                                    <td>帐号</td>
                                    <td>走飞笔数</td>
                                    <td>走飞金额</td>
                                    <td>收飞笔数</td>
                                    <td>收飞金额</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td>{{ summary.username }}</td>
                                    <td>{{ summary.transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,1)">{{ summary.transfer_amount_out }}</a></td>
                                    <td>{{ summary.reward_number }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,1)">{{ summary.reward_amount }}</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="Filter.status == 1" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <thead>
                                <tr style="text-align:left;">
                                    <td>帐号</td>
                                    <td>笔数</td>
                                    <td>走飞金额</td>
                                    <td>走飞退水</td>
                                    <td>走飞结果</td>
                                    <td>总结果合计</td>
                                    <td v-if="!TargetIsAdmin">上级结果合计</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td v-mark>{{ summary.transfer_username_out }}</td>
                                    <td v-mark>{{ summary.transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,1)">{{ summary.transfer_amount_out }}</a></td>
                                    <td v-mark>{{ summary.transfer_backwater_out }}</td>
                                    <td v-mark>{{ summary.transfer_result_out }}</td>
                                    <td v-mark>{{ summary.total_result }}</td>
                                    <td v-if="!TargetIsAdmin" v-mark>{{ summary.total_up_result }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>


                </template>


                <template v-if="Filter.games_type > 7">

                    <table id="CreditSixAmount-table-4" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0">
                        <thead>
                            <tr style="text-align:center;">
                                <td rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">帐号</div></td>
                                <td rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">名称</div></td>
                                <td v-if="Opt.isTeam" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">级别</div></td>
                                <td v-if="Filter.status == 0 && Opt.isTeam" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">关系</div></td>
                                <td rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下单数</div></td>
                                <td rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">下注金额</div></td>
                                <!-- <td v-if="Opt.isTeam" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">有效金额</div></td> -->
                                <td v-if="!Opt.isTeam" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">奖金</div></td>
                                <td v-if="!Opt.isTeam" rowspan="2"><div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">退水</div></td>
                                <td rowspan="2">
                                    <div :class="{'table-row-2': !Opt.isTeam || Filter.status == 0}">
                                        <span v-if="Opt.isTeam">会员结果</span>
                                        <span v-else>结果</span>
                                    </div>
                                </td>
                                <template v-if="Opt.isTeam && Filter.status != 0">
                                    <td rowspan="2">{{ isTop ? '代理结果' : '交收' }}</td>
                                    <td v-if="!isTop" style="text-align:center;" colspan="4">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                    <td v-if="!isTop" style="text-align:center;" colspan="5">代理收飞</td>
                                    <td v-if="isTop" rowspan="2">收飞金额</td>
                                    <td v-if="isTop" rowspan="2">收飞结果</td>
                                    <td rowspan="2">总结果</td>
                                    <td v-if="!isTop && Filter.pid != 1" rowspan="2">上级结果</td>
                                    <td rowspan="2">贡献1</td>
                                    <td v-if="Filter.pid != 1" rowspan="2">贡献2</td>
                                    <td rowspan="2">贡献比例</td>
                                </template>
                            </tr>
                            <tr v-if="Opt.isTeam && !isTop && Filter.status != 0" style="text-align:center;">
                                <td>退水</td>
                                <td>金额</td>
                                <td>占成</td>
                                <td>结果</td>
                                <td>退水</td>
                                <td>金额</td>
                                <td>输赢</td>
                                <td>占成</td>
                                <td>结果</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr v-for="(item,index) in main._list" v-show="!!summary && !main._loading" :key="item.id">
                                <td style="text-align:left;">
                                    <a class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,0)">{{ item.username }}</a>
                                </td>
                                <td style="text-align:left;">
                                    <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                                </td>
                                <td v-if="Opt.isTeam">
                                    <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                                </td>
                                <td v-if="Filter.status == 0 && Opt.isTeam">
                                    <a class="link" @click="LevelView(item)">关系</a>
                                </td>
                                <td style="text-align:left;">{{ item.bets_num }}</td>
                                <td style="text-align:left;">
                                    <a v-mark class="link" style="display:inline-block; min-width:80px;" @click="GetByBreadcrumb(item,0)">{{ item.bets_amount }}</a>
                                </td>

                                <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ item.valid_amount }}</td> -->
                                <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.bets_bonus }}</td>
                                <td v-if="!Opt.isTeam" v-mark style="text-align:left;">{{ item.percent_backwater_amount }}</td>
                                <td v-mark style="text-align:left;">{{ item.members_result }}</td>
                                <template v-if="Opt.isTeam && Filter.status != 0">
                                    <td v-mark style="text-align:left;">{{ item.js_result }}</td>

                                    <template v-if="!isTop">
                                        <td v-mark style="text-align:left;">{{ Price(item.percent_backwater_amount,{separate: false}) }}</td>
                                        <td v-mark style="text-align:left;">{{ item.percent_amount }}</td>
                                        <td v-mark style="text-align:left;">{{ item.percent }}</td>
                                        <td v-mark style="text-align:left;">{{ item.percent_result }}</td>

                                        <td v-mark style="text-align:left;">{{ Price(item.transfer_backwater,{separate: false}) }}</td>
                                        <td v-mark style="text-align:left;"><a class="link" @click="Edit(item,1)">{{ item.transfer_amount }}</a></td>
                                        <td v-mark style="text-align:left;">{{ item.transfer_wins }}</td>
                                        <td v-mark style="text-align:left;">{{ item.transfer }}</td>
                                        <td v-mark style="text-align:left;">{{ item.transfer_result }}</td>
                                    </template>

                                    <td v-if="isTop" style="text-align:left;"><a v-mark class="link" @click="Edit(item,1)">{{ item.transfer_amount }}</a></td>
                                    <td v-if="isTop" v-mark style="text-align:left;">{{ item.transfer_result }}</td>

                                    <td v-mark style="text-align:left;">{{ item.result }}</td>
                                    <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ item.up_result }}</td>
                                    {{/* 奉献额与奉献比率 */}}
                                    <td v-mark style="text-align:left;">{{ item.contribution_one }}</td>
                                    <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ item.contribution_two }}</td>
                                    <td style="text-align:center;">
                                        <div class="l-flex-c o-pr-s">
                                            <el-progress class="PrintHide" style="width:55px;" :percentage="CalcuNumber(item.contribution_proportion)" :show-text="false" />
                                            <p class="l-flex-1 c-text-6 c-text-r">{{ item.contribution_proportion }}%</p>
                                        </div>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                        <tfoot>
                            <!-- <tr v-if="main._empty">
                                <td class="empty" colspan="100">暂无数据</td>
                            </tr> -->
                            <template v-if="!main._empty && Opt.isTeam">
                                <tr v-if="main._count >= 50" class="subtotal">
                                    <td style="text-align:left;" colspan="3">小计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('bets_num') }}</td>
                                    <td v-mark style="text-align:left;">{{ CalcuRow('bets_amount') }}</td>
                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ CalcuRow('valid_amount') }}</td> -->
                                    <td v-mark style="text-align:left;">{{ CalcuRow('members_result') }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ CalcuRow('js_result') }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ CalcuRow('percent_backwater_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('percent_amount') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('percent_result') }}</td>

                                            <td v-mark style="text-align:left;">{{ CalcuRow('transfer_backwater') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('transfer_amount') }}</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('transfer_wins') }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ CalcuRow('transfer_result') }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('transfer_amount') }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ CalcuRow('transfer_result') }}</td>

                                        <td v-mark style="text-align:left;">{{ CalcuRow('result') }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('up_result') }}</td>
                                    </template>

                                    <td v-mark style="text-align:left;">{{ CalcuRow('contribution_one') }}</td>
                                    <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ CalcuRow('contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </tr>
                                <tr v-if="!main._empty && summary" v-show="!main._loading" class="total">
                                    <td style="text-align:left;" colspan="3">合计</td>
                                    <td v-if="Filter.status == 0">-</td>

                                    <td v-mark style="text-align:left;">{{ summary.bets_num }}</td>
                                    <td v-mark style="text-align:left;">{{ summary.bets_amount }}</td>
                                    <!-- <td v-if="Opt.isTeam" v-mark style="text-align:left;">{{ summary.valid_amount }}</td> -->
                                    <td v-mark style="text-align:left;">{{ summary.members_result }}</td>
                                    <template v-if="Filter.status != 0">
                                        <td v-mark style="text-align:left;">{{ summary.js_result }}</td>

                                        <template v-if="!isTop">
                                            <td v-mark style="text-align:left;">{{ summary.percent_backwater_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.percent_amount }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.percent_result }}</td>

                                            <td v-mark style="text-align:left;">{{ summary.transfer_backwater }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.transfer_amount }}</td>
                                            <td v-mark style="text-align:left;">{{ summary.transfer_wins }}</td>
                                            <td style="text-align:left;">-</td>
                                            <td v-mark style="text-align:left;">{{ summary.transfer_result }}</td>
                                        </template>

                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.transfer_amount }}</td>
                                        <td v-if="isTop" v-mark style="text-align:left;">{{ summary.transfer_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.result }}</td>
                                        <td v-if="!isTop && Filter.pid != 1" v-mark style="text-align:left;" by="上级结果">{{ summary.up_result }}</td>

                                        <td v-mark style="text-align:left;">{{ summary.contribution_one }}</td>
                                        <td v-if="Filter.pid != 1" v-mark style="text-align:left;">{{ summary.contribution_two }}</td>
                                        <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                    </template>
                                </tr>
                            </template>
                        </tfoot>
                    </table>
                    <template v-if="Opt.isTeam && summary && summary.transfer_number_out > 0 && !main._loading">
                        <table v-if="Filter.status == 0" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <colgroup>
                            <col width="130">
                            <col width="195">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                            <thead>
                                <tr style="text-align:left;">
                                    <td>帐号</td>
                                    <td>走飞笔数</td>
                                    <td>走飞金额</td>
                                    <td>收飞笔数</td>
                                    <td>收飞金额</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td>{{ summary.username }}</td>
                                    <td>{{ summary.transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,1)">{{ summary.transfer_amount_out }}</a></td>
                                    <td>{{ summary.reward_number }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,1)">{{ summary.reward_amount }}</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="Filter.status == 1" v-show="!main._loading" v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                            <thead>
                                <tr style="text-align:left;">
                                    <td>帐号</td>
                                    <td>笔数</td>
                                    <td>走飞金额</td>
                                    <td>走飞退水</td>
                                    <td>走飞结果</td>
                                    <td>总结果合计</td>
                                    <td v-if="!TargetIsAdmin">上级结果合计</td>
                                </tr>
                            </thead>
                            <tbody style="text-align:left;">
                                <tr>
                                    <td v-mark>{{ summary.transfer_username_out }}</td>
                                    <td v-mark>{{ summary.transfer_number_out }}</td>
                                    <td v-mark><a class="link" @click="Edit(summary,1)">{{ summary.transfer_amount_out }}</a></td>
                                    <td v-mark>{{ summary.transfer_backwater_out }}</td>
                                    <td v-mark>{{ summary.transfer_result_out }}</td>
                                    <td v-mark>{{ summary.total_result }}</td>
                                    <td v-if="!TargetIsAdmin" v-mark>{{ summary.total_up_result }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </template>


            </div>
            <Pagination v-if="main._count >= 50" v-model="Page" class="o-mtb" :total="main._total" :count="main._count" :page-size="Filter.per_page" @turning="Get" />
        </div>
        <editer v-if="target && target.type == 1" v-model="Editer.view" title="注单详情" :form="Editer.form" />
        <level v-model="Level.view" title="占成" :form="Level.form" />
        <bets v-show="target && target.type == 0" ref="bets" :target="target" :filter="Filter" />
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import LevelMix from '@lottery.2.0/mixin/level.js'
import Editer from './modul/amount'
import Level from '../model/user_level'
import Bets from './amount_bets'
export default {
    name: 'CreditSixAmount',
    mixins: [StoreMix, LevelMix],
    data() {
        return {
            crumbkey: 'pid',
            Params: {},
            Filter: {
                games_type: null,
                per_page: 50,
                pid: null
            },
            summary: null,
            test: true,
            Level: {
                view: false,
                form: null
            }
        }
    },
    props: ['form', 'size', 'target'],
    computed: {
        store() {
            return 'credit/report'
        },
        isTop() {
            if (this.test) {
                return false
            }
            if (this.Opt.isAdmin && this.breadcrumb.length <= 1) {
                return true
            }
            return false
        },
        TargetIsAdmin() {
            if (this.breadcrumb && this.breadcrumb.length) {
                let index = this.breadcrumb.length - 1
                let target = this.breadcrumb[index]
                return target.is_admin
            }
            return false
        }
    },
    watch: {
        'breadcrumb.length'(val) {
            this.GetSum()
        }
    },
    directives: {
        mark: {
            inserted(el, e, vnode, oldvnode) {
                el.setAttribute('mark', el.innerText && el.innerText < 0 ? 'true' : 'false')
            },
            update(el, binding, vnode, oldvnode) {
                el.setAttribute('mark', el.innerText && el.innerText < 0 ? 'true' : 'false')
            }
        }
    },
    methods: {
        init() {
            this.DataReset('breadcrumb')
            this.DataReset('Filter')
            this.Filter.pid = this.User.id
            this.breadcrumb[0].id = this.User.id
            this.Clean()
            this.$set(this, 'summary', null)
            if (this.form && typeof this.form === 'object') {
                for (let key in this.form) {
                    if (this.form[key] || this.form[key]==0) {
                        this.$set(this.Filter, key, this.form[key])
                    }
                }
            }
            if (this.target && this.target.type == 1) {
                this.Get(1)
                this.GetSum()
            } else {
                this.$nextTick(() => {
                    this.$refs['bets'].init()
                })
            }
        },
        GetSum() {
            this.Get('reportsum').then(res => {
                this.$set(this, 'summary', res.data)
            })
        },
        CalcuNumber(number) {
            try {
                return this.Big(number).round(2, 0).toNumber()
            } catch (err) {
                return 0
            }
        },
        Edit(item, transfer) {
            if (this.Filter.status == 0) {
                this.Editer.form = {
                    uid: item.id,
                    whole: 1,
                    //games_id : 31,
                    games_type: this.Filter.games_type,
                    interval: this.Origin(this.Filter.interval),
                    // username : item.username,
                    lottery_id: this.Filter.lottery_id,
                    status: this.$route.query.status,
                    transfer:transfer
                }
            } else {
                this.Editer.form = {
                    uid: item.id,
                    games_type: this.Filter.games_type,
                    interval: this.Origin(this.Filter.interval),
                    username: item.username,
                    lottery_id: this.Filter.lottery_id,
                    status: this.$route.query.status,
                    transfer:transfer
                }
            }
            this.Editer.view = true
        },
        puls_reduce(id) {
            if (document.getElementById(id+'_data').style.display == 'none') {
                document.getElementById(id+'_puls').innerText="-";
                document.getElementById(id+'_data').style.display="";
            } else {
                document.getElementById(id+'_puls').innerText="+";
                document.getElementById(id+'_data').style.display="none";
            }
        },
        LevelView(item) {
            this.Level.form = this.Origin(item)
            this.Level.view = true
        },
        Breadcrumb(index) {
            this.$emit('Breadcrumb', index)
        },
        GetByBreadcrumb(item, val) {
            localStorage.setItem('games_mark_senbo', val)
            this.$emit('GetByBreadcrumb', item)
        },
        Print() {
            if (this.target.type == 1) {
                this.PrintTable()
            } else {
                this.$refs['bets'].PrintTable()
            }
        }
    },
    components: {
        Editer,
        Level,
        Bets
    },
    mounted() {
        this.init()
    }
}
</script>
