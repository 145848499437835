<style lang="scss">
.MyTeamUserModul {
    .el-dialog {
        width:52rem!important;
    }
    .el-input__inner {
        border-color:#bbb;
    }
    .el-input-group__append, .el-input-group__prepend {
        padding:0 12px;
    }
    .el-checkbox+.el-checkbox {
        margin-left:18px;
    }
    .el-checkbox__label {
        padding-left:6px;
    }
    .parent {
        padding:5px; border-radius:4px; background-color:#f5f5f5;
    }
    .ins {
        padding-left:8px; padding-right:8px;
    }
    .el-input.small, .el-input[size=small] {
        input {
            height: 28px!important; line-height: 28px;
        }
    }
    .model_font {
        .el-input__inner {
            font-size: 14px;
        }
    }
    table.table {
        tr {
            td {
                padding-left:8px!important; padding-right:8px!important; padding-top:5px!important; padding-bottom:5px!important; border-color:#ddd;
            }
            td.label {
                width:110px!important; background-color:#d5d1f9;
            }
            td.label-2 {
                width:90px; background-color:#b0e5f2;
            }
            td.head {
                background-color:#7BAAFF!important; padding-top:7px!important; padding-bottom:7px!important; font-size:.75rem;
            }
            td.head-2 {
                background-color:#e0e0e0!important; padding-top:7px!important; padding-bottom:7px!important; font-size:.75rem;
            }
        }
        tbody{
            tr {
                background-color:#fff!important;
            }
            tr:nth-child(odd) {
                background-color:#fff!important;
            }
            tr:hover {
                cursor:auto; background-color:#fff!important;
            }
        }
        td.interval{
            padding-top:1px!important; padding-bottom:1px!important; background-color:#fff!important; border-left:none!important; border-right:none!important;
        }
    }
}
</style>

<template>
    <el-dialog v-loading="agent.loading" class="MyTeamUserModul" :title="title" :visible.sync="view" size="big" top="5vh" :modal-append-to-body="false">
        <table class="table line">
            <thead>
                <tr>
                    <td colspan="9" class="head">基本资料</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="label">登录帐号</td>
                    <td style="text-align:left;" colspan="2">
                        <el-input v-model="Params.username" class="small model_font" style="width:144px;" placeholder="请输入登录帐号" :disabled="!!Params.id" />
                        <span v-if="userCheck.pass === true"><i class="el-icon-success c-color-s" /><span class="o-pl-s">帐号名可用</span></span>
                        <span v-if="userCheck.pass === false"><i class="el-icon-error c-color-e" /><span class="o-pl-s">被占用</span></span>
                    </td>
                    <td class="label">真实姓名</td>
                    <td style="text-align:left;" colspan="2"><el-input v-model="Params.real_name" class="small model_font" style="width:144px;" placeholder="请输入真实姓名" /></td>
                    <td class="label">所属上级</td>
                    <td style="text-align:left;" class="small model_font" colspan="2">{{ agent.info.username }}</td>
                </tr>
                <tr>
                    <td class="label">登录密码</td>
                    <td style="text-align:left;" colspan="2"><el-input v-model="Params.pass" class="small model_font" style="width:144px;" placeholder="请输入登录密码" /></td>
                    <td class="label">账户类型</td>
                    <td style="text-align:left;" colspan="2">
                        <!--                         <el-radio v-model="Params.type" size="mini" :disabled="true" :label="0" class="o-pr-l">会员</el-radio>
                        <el-radio v-model="Params.type" size="mini" :disabled="true" :label="1">代理</el-radio> -->
                        <span  v-if="Params.type == 0" class="o-plr-s model_font">会员</span>
                        <span v-if="Params.type == 1" class="o-plr-s model_font">代理</span>
                    </td>
                    <td v-if="Params.type == 0" class="label">额度恢复类型</td>
                    <td v-if="Params.type == 0" style="text-align:left;" colspan="2">
                        <el-radio v-model="Params.amount_type" size="small" :label="1" class="o-pr-l">自动</el-radio>
                        <el-radio v-model="Params.amount_type" size="small" :label="0">手动</el-radio>
                    </td>
                </tr>
                <tr>
                    <td class="label">账户状态</td>
                    <td style="text-align:left;margin-right:0px;" colspan="2">
                        <el-radio v-model="Params.status" size="mini" :label="1" class="o-pr">启用</el-radio>
                        <el-radio v-model="Params.status" size="mini" :label="2" class="o-pr">暂停</el-radio>
                        <el-radio v-model="Params.status" size="mini" :label="3">停用</el-radio>
                    </td>
                    <td class="label">备注</td>
                    <td colspan="5"><el-input v-model="Params.note" class="small model_font" placeholder="请输入备注" /></td>
                </tr>
            </tbody>
        </table>
        <table class="table line o-mt">
            <tbody v-for="(item,index) in hasDir">
                <tr>
                    <td v-if="index" class="label" colspan="6" style="background-color:#FFF;" />
                </tr>
                <tr>
                    <td class="label" :rowspan="Params.type == 1 && Params[item.name + '_enable'] ? 7 : 6" style="vertical-align:middle;">{{ item.title }}</td>
                    <td style="width:90px;">启用：<el-checkbox v-model="Params[item.name + '_enable']" class="o-pr" @change="EnableGame(item.name,$event)" /></td>
                    <td style="text-align:left;" colspan="3">
                        <!-- <el-checkbox-group v-if="item.name == 'ssc'" v-model="ssc_has_bet" :disabled="!Params[item.name + '_enable']" style="display:inline-block; padding-left:1rem;margin-right:0px;">
                            <el-checkbox v-for="bet in ['a','b','c','d','e']" :key="bet" :label="bet" :disabled="!item.bets[bet]">{{ bet.toUpperCase() }}盘</el-checkbox>
                        </el-checkbox-group>
                        <el-checkbox-group v-if="item.name == 'ffc'" v-model="ffc_has_bet" :disabled="!Params[item.name + '_enable']" style="display:inline-block; padding-left:1rem;margin-right:0px;">
                            <el-checkbox v-for="bet in ['a','b','c','d','e']" :key="bet" :label="bet" :disabled="!item.bets[bet]">{{ bet.toUpperCase() }}盘</el-checkbox>
                        </el-checkbox-group>
                        <el-checkbox-group v-if="item.name == 'klc'" v-model="klc_has_bet" :disabled="!Params[item.name + '_enable']" style="display:inline-block; padding-left:1rem;margin-right:0px;">
                            <el-checkbox v-for="bet in ['a','b','c','d','e']" :key="bet" :label="bet" :disabled="!item.bets[bet]">{{ bet.toUpperCase() }}盘</el-checkbox>
                        </el-checkbox-group>
                        <el-checkbox-group v-if="item.name == 'pks'" v-model="pks_has_bet" :disabled="!Params[item.name + '_enable']" style="display:inline-block; padding-left:1rem;margin-right:0px;">
                            <el-checkbox v-for="bet in ['a','b','c','d','e']" :key="bet" :label="bet" :disabled="!item.bets[bet]">{{ bet.toUpperCase() }}盘</el-checkbox>
                        </el-checkbox-group>
                        <el-checkbox-group v-if="item.name == 'dpc'" v-model="dpc_has_bet" :disabled="!Params[item.name + '_enable']" style="display:inline-block; padding-left:1rem;margin-right:0px;">
                            <el-checkbox v-for="bet in ['a','b','c','d','e']" :key="bet" :label="bet" :disabled="!item.bets[bet]">{{ bet.toUpperCase() }}盘</el-checkbox>
                        </el-checkbox-group>
                        <el-checkbox-group v-if="item.name == 'syw'" v-model="syw_has_bet" :disabled="!Params[item.name + '_enable']" style="display:inline-block; padding-left:1rem;margin-right:0px;">
                            <el-checkbox v-for="bet in ['a','b','c','d','e']" :key="bet" :label="bet" :disabled="!item.bets[bet]">{{ bet.toUpperCase() }}盘</el-checkbox>
                        </el-checkbox-group> -->
                        <el-checkbox-group v-if="item.name == 'lhc'" v-model="lhc_has_bet" :disabled="!Params[item.name + '_enable']" style="display:inline-block; padding-left:1rem;">
                            <el-checkbox style="margin:9px;" v-for="bet in ['a','b','c','d','e']" :key="bet" :label="bet" :disabled="!item.bets[bet]">{{ bet.toUpperCase() }}盘</el-checkbox>
                        </el-checkbox-group>

                        <span class="o-pl-h o-pr">额度:</span>
                        <el-input v-model.number="Params[item.name + '_amount']" size="small" class="small model_font" style="width:100px;" :disabled="!Params[item.name + '_enable']" />
                        <span class="c-text-6 c-color-e">（当前：{{ NumberTransformInt(Params[item.name + '_amount']) }}，最高：{{ NumberTransformInt(CheckAmount(item.name)) }}）</span>
                    </td>
                </tr>
                <!-- hui yuan -->
                <template v-if="Params.type == 0 && Params[item.name+'_enable']">
                        <tr>
                            <td style="text-align:left;" colspan="2">
                                <table style="width:100%;">
                                    <tr>
                                        <td style="background:#f0f0f0;">新澳门代理占成</td>
                                        <td style="background:#f0f0f0;">上限</td>
                                        <td style="background:#f0f0f0;">说明</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <template>
                                                <el-input v-model.number="Params[item.name+'_proportion_max_xac']" class="small model_font" style="width:80px;"></el-input>
                                                <span>%</span>
                                            </template>
                                        </td>
                                        <td>
                                            <b class="c-color-e model_font" v-if="agent.info.is_admin">-</b>
                                            <b class="c-color-e model_font" v-else>{{ Price(agent.info[item.name+'_proportion_xac'],{separate:false}) }}</b>
                                        </td>
                                        <td>
                                            代理当前占成必须小于或者等于上线。
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </template>
                <template v-if="Params.type == 0 && Params[item.name+'_enable']">
                        <tr>
                            <td style="text-align:left;" colspan="2">
                                <table style="width:100%;">
                                    <tr>
                                        <td style="background:#f0f0f0;">香港代理占成&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                        <td style="background:#f0f0f0;">上限</td>
                                        <td style="background:#f0f0f0;">说明</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <template>
                                                <el-input v-model.number="Params[item.name+'_proportion_max']" class="small model_font" style="width:80px;"></el-input>
                                                <span>%</span>
                                            </template>
                                        </td>
                                        <td>
                                            <b class="c-color-e model_font" v-if="agent.info.is_admin">-</b>
                                            <b class="c-color-e model_font" v-else>{{ Price(agent.info[item.name+'_proportion'],{separate:false}) }}</b>
                                        </td>
                                        <td>
                                            代理当前占成必须小于或者等于上线。
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </template>
                <template v-if="Params.type == 0 && Params[item.name + '_enable']">
                    <tr>
                        <td style="text-align:left;" colspan="2">
                            <table style="width:100%;">
                                <tr>
                                    <td style="background:#f0f0f0;">旧澳门代理占成</td>
                                    <td style="background:#f0f0f0;">上限</td>
                                    <td style="background:#f0f0f0;">说明</td>
                                </tr>
                                <tr>
                                    <td>
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_proportion_max_ac']"  class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                        </template>
                                    </td>
                                    <td>
                                        <b v-if="agent.info.is_admin" class="c-color-e model_font">-</b>
                                        <b v-else class="c-color-e model_font">{{ Price(agent.info[item.name + '_proportion_ac'],{separate: false}) }}</b>
                                    </td>
                                    <td>
                                        代理当前占成必须小于或者等于上线。
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </template>
                <template v-if="Params.type == 0 && Params[item.name+'_enable']">
                        <tr>
                            <td style="text-align:left;" colspan="2">
                                <table style="width:100%;">
                                    <tr>
                                        <td style="background:#f0f0f0;">快乐8代理占成&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                        <td style="background:#f0f0f0;">上限</td>
                                        <td style="background:#f0f0f0;">说明</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <template>
                                                <el-input v-model.number="Params[item.name+'_proportion_max_tw']"  class="small model_font" style="width:80px;"></el-input>
                                                <span>%</span>
                                            </template>
                                        </td>
                                        <td>
                                            <b class="c-color-e model_font" v-if="agent.info.is_admin">-</b>
                                            <b class="c-color-e model_font" v-else>{{ Price(agent.info[item.name+'_proportion_tw'],{separate:false}) }}</b>
                                        </td>
                                        <td>
                                            代理当前占成必须小于或者等于上线。
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </template>
                <!-- <template v-if="Params.type == 0 && Params[item.name+'_enable']">
                        <tr>
                            <td style="text-align:left;" colspan="2">
                                <table style="width:100%;">
                                    <tr>
                                        <td style="background:#f0f0f0;">新加坡代理占成</td>
                                        <td style="background:#f0f0f0;">上限</td>
                                        <td style="background:#f0f0f0;">说明</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <template>
                                                <el-input v-model.number="Params[item.name+'_proportion_max_xjp']" class="small model_font" style="width:80px;"></el-input>
                                                <span>%</span>
                                            </template>
                                        </td>
                                        <td>
                                            <b class="c-color-e model_font" v-if="agent.info.is_admin">-</b>
                                            <b class="c-color-e model_font" v-else>{{ Price(agent.info[item.name+'_proportion_xjp'],{separate:false}) }}</b>
                                        </td>
                                        <td>
                                            代理当前占成必须小于或者等于上线。
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </template> -->
                    <!-- daili -->

                                           <template v-if="Params.type == 1 && Params[item.name + '_enable']">
                    <tr>
                        <td style="text-align:left;" colspan="2">
                            <table style="width:100%;">
                                <tr>
                                    <!-- <td style="background:#f0f0f0;" v-if="agent.info.id!=1">新澳上级代理最小占成</td> -->
                                    <td v-if="agent.info.id != 1" style="background:#f0f0f0;">新澳门上级代理最大占成</td>
                                    <td v-if="agent.info.id != 1" style="background:#f0f0f0;">新澳门上级代理收飞最大占成</td>
                                    <td style="background:#f0f0f0;">新澳门上限&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td style="background:#f0f0f0;">新澳门下级代理可占成</td>
                                    <td style="background:#f0f0f0;">新澳门锁定下级代理占成</td>
                                </tr>
                                <tr>
                                    <!-- <td v-if="!agent.info.is_admin">
                                            <template>
                                                <el-input v-model.number="Params[item.name+'_proportion_min_ac']" maxlength="3"  style="width:80px;"></el-input>
                                                <span>%</span>
                                            </template>
                                        </td> -->
                                    <td v-if="!agent.info.is_admin">
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_proportion_max_xac']" class="small model_font" maxlength="3" style="width:80px;" />
                                            <span>%</span>
                                            <span class="c-color-e model_font">>={{ Price(agent.info[item.name + '_lock_proportion_xac'],{separate: false}) }}%</span>
                                        </template>
                                    </td>
                                    <td v-if="!agent.info.is_admin">
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_transfer_rebate_xac']" maxlength="3" class="small model_font"  style="width:80px;" />
                                            <span>%</span>
                                            <span class="c-color-e model_font">>={{ Price(agent.info[item.name + '_lock_proportion_xac'],{separate: false}) }}%</span>
                                        </template>
                                    </td>
                                    <td>
                                        <b v-if="agent.info.is_admin" class="c-color-e model_font">-</b>
                                        <b v-else class="c-color-e model_font">{{ Price(agent.info[item.name + '_proportion_xac'],{separate: false}) }}</b>
                                    </td>
                                    <td>
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_proportion_xac']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                        </template>
                                    </td>
                                    <td>
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_lock_proportion_xac']" maxlength="3" class="small model_font"  style="width:80px;" />
                                            <span>%</span>
                                        </template>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </template>

                <template v-if="Params.type == 1 && Params[item.name+'_enable']">
                        <tr>
                            <td style="text-align:left;" colspan="2">
                                <table style="width:100%;">
                                    <tr>
                                        <!-- <td style="background:#f0f0f0;" v-if="agent.info.id!=1">港上级代理最小占成</td> -->
                                        <td style="background:#f0f0f0;" v-if="agent.info.id!=1">香港上级代理最大占成</td>
                                        <td style="background:#f0f0f0;" v-if="agent.info.id!=1">香港上级代理收飞最大占成</td>
                                        <td style="background:#f0f0f0;">香港上限&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                        <td style="background:#f0f0f0;">香港下级代理可占成</td>
                                        <td style="background:#f0f0f0;">香港锁定下级代理占成</td>
                                    </tr>
                                    <tr>
                                        <!-- <td v-if="!agent.info.is_admin">
                                            <template>
                                                <el-input v-model.number="Params[item.name+'_proportion_min']" maxlength="3" style="width:80px;"></el-input>
                                                <span>%</span>
                                            </template>
                                        </td> -->
                                        <td v-if="!agent.info.is_admin">
                                            <template>
                                                <el-input v-model.number="Params[item.name+'_proportion_max']" maxlength="3" class="small model_font" style="width:80px;"></el-input>
                                                <span>%</span>
                                                <span class="c-color-e model_font">>={{ Price(agent.info[item.name+'_lock_proportion'],{separate:false}) }}%</span>
                                            </template>
                                        </td>
                                        <td v-if="!agent.info.is_admin">
                                            <template>
                                                <el-input v-model.number="Params[item.name+'_transfer_rebate']" maxlength="3" class="small model_font" style="width:80px;"></el-input>
                                                <span>%</span>
                                                <span class="c-color-e model_font">>={{ Price(agent.info[item.name+'_lock_proportion'],{separate:false}) }}%</span>
                                            </template>
                                        </td>
                                        <td>
                                            <b class="c-color-e model_font" v-if="agent.info.is_admin">-</b>
                                            <b class="c-color-e model_font" v-else>{{ Price(agent.info[item.name+'_proportion'],{separate:false}) }}</b>
                                        </td>
                                        <td>
                                            <template>
                                                <el-input v-model.number="Params[item.name+'_proportion']" maxlength="3" class="small model_font" style="width:80px;"></el-input>
                                                <span>%</span>
                                            </template>
                                        </td>
                                        <td>
                                            <template>
                                                <el-input v-model.number="Params[item.name+'_lock_proportion']" maxlength="3" class="small model_font" style="width:80px;"></el-input>
                                                <span>%</span>
                                            </template>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <!-- <tr v-if="item.name === 'lhc' || agent.info.is_admin">
                            <td style="text-align:left;" colspan="2">
                                <span v-if="agent.info.is_admin" class="o-pr-l">
                                    <span>开启操盘：</span>
                                    <el-checkbox v-model="Params[item.name+'_trader_status']" :true-label="1" :false-label="0"></el-checkbox>
                                </span>
                                <span v-if="item.name=='lhc'" >六合彩封盘时间：</span>
                                <el-time-picker v-if="item.name=='lhc'" v-model="Params.lhc_end_bets_date" value-format="HH:mm:ss" placeholder="选择时间" style="width:120px;"></el-time-picker>
                            </td>
                        </tr> -->
                    </template>
                <template v-if="Params.type == 1 && Params[item.name + '_enable']">
                    <tr>
                        <td style="text-align:left;" colspan="2">
                            <table style="width:100%;">
                                <tr>
                                    <!-- <td style="background:#f0f0f0;" v-if="agent.info.id!=1">澳上级代理最小占成</td> -->
                                    <td v-if="agent.info.id != 1" style="background:#f0f0f0;">旧澳门上级代理最大占成</td>
                                    <td v-if="agent.info.id != 1" style="background:#f0f0f0;">旧澳门上级代理收飞最大占成</td>
                                    <td style="background:#f0f0f0;">旧澳门上限&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td style="background:#f0f0f0;">旧澳门下级代理可占成</td>
                                    <td style="background:#f0f0f0;">旧澳门锁定下级代理占成</td>
                                </tr>
                                <tr>
                                    <!-- <td v-if="!agent.info.is_admin">
                                            <template>
                                                <el-input v-model.number="Params[item.name+'_proportion_min_ac']" maxlength="3" style="width:80px;"></el-input>
                                                <span>%</span>
                                            </template>
                                        </td> -->
                                    <td v-if="!agent.info.is_admin">
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_proportion_max_ac']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                            <span class="c-color-e model_font">>={{ Price(agent.info[item.name + '_lock_proportion_ac'],{separate: false}) }}%</span>
                                        </template>
                                    </td>
                                    <td v-if="!agent.info.is_admin">
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_transfer_rebate_ac']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                            <span class="c-color-e model_font">>={{ Price(agent.info[item.name + '_lock_proportion_ac'],{separate: false}) }}%</span>
                                        </template>
                                    </td>
                                    <td>
                                        <b v-if="agent.info.is_admin" class="c-color-e model_font">-</b>
                                        <b v-else class="c-color-e model_font">{{ Price(agent.info[item.name + '_proportion_ac'],{separate: false}) }}</b>
                                    </td>
                                    <td>
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_proportion_ac']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                        </template>
                                    </td>
                                    <td>
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_lock_proportion_ac']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                        </template>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </template>

     <template v-if="Params.type == 1 && Params[item.name + '_enable']">
                    <tr>
                        <td style="text-align:left;" colspan="2">
                            <table style="width:100%;">
                                <tr>
                                    <!-- <td style="background:#f0f0f0;" v-if="agent.info.id!=1">新澳上级代理最小占成</td> -->
                                    <td v-if="agent.info.id != 1" style="background:#f0f0f0;">快乐8上级代理最大占成</td>
                                    <td v-if="agent.info.id != 1" style="background:#f0f0f0;">快乐8上级代理收飞最大占成</td>
                                    <td style="background:#f0f0f0;">快乐8上限&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td style="background:#f0f0f0;">快乐8下级代理可占成</td>
                                    <td style="background:#f0f0f0;">快乐8锁定下级代理占成</td>
                                </tr>
                                <tr>
                                    <!-- <td v-if="!agent.info.is_admin">
                                            <template>
                                                <el-input v-model.number="Params[item.name+'_proportion_min_ac']" maxlength="3" style="width:80px;"></el-input>
                                                <span>%</span>
                                            </template>
                                        </td> -->
                                    <td v-if="!agent.info.is_admin">
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_proportion_max_tw']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                            <span class="c-color-e model_font">>={{ Price(agent.info[item.name + '_lock_proportion_tw'],{separate: false}) }}%</span>
                                        </template>
                                    </td>
                                    <td v-if="!agent.info.is_admin">
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_transfer_rebate_tw']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                            <span class="c-color-e model_font">>={{ Price(agent.info[item.name + '_lock_proportion_tw'],{separate: false}) }}%</span>
                                        </template>
                                    </td>
                                    <td>
                                        <b v-if="agent.info.is_admin" class="c-color-e model_font">-</b>
                                        <b v-else class="c-color-e model_font">{{ Price(agent.info[item.name + '_proportion_tw'],{separate: false}) }}</b>
                                    </td>
                                    <td>
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_proportion_tw']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                        </template>
                                    </td>
                                    <td>
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_lock_proportion_tw']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                        </template>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </template>


  <!-- <template v-if="Params.type == 1 && Params[item.name + '_enable']">
                    <tr>
                        <td style="text-align:left;" colspan="2">
                            <table style="width:100%;">
                                <tr>
                                
                                    <td v-if="agent.info.id != 1" style="background:#f0f0f0;">新加坡上级代理最大占成</td>
                                    <td v-if="agent.info.id != 1" style="background:#f0f0f0;">新加坡上级代理收飞最大占成</td>
                                    <td style="background:#f0f0f0;">新加坡上限&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td style="background:#f0f0f0;">新加坡下级代理可占成</td>
                                    <td style="background:#f0f0f0;">新加坡锁定下级代理占成</td>
                                </tr>
                                <tr>
                  
                                    <td v-if="!agent.info.is_admin">
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_proportion_max_xjp']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                            <span class="c-color-e model_font">>={{ Price(agent.info[item.name + '_lock_proportion_xjp'],{separate: false}) }}%</span>
                                        </template>
                                    </td>
                                    <td v-if="!agent.info.is_admin">
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_transfer_rebate_xjp']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                            <span class="c-color-e model_font">>={{ Price(agent.info[item.name + '_lock_proportion_xjp'],{separate: false}) }}%</span>
                                        </template>
                                    </td>
                                    <td>
                                        <b v-if="agent.info.is_admin" class="c-color-e model_font">-</b>
                                        <b v-else class="c-color-e model_font">{{ Price(agent.info[item.name + '_proportion_xjp'],{separate: false}) }}</b>
                                    </td>
                                    <td>
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_proportion_xjp']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                        </template>
                                    </td>
                                    <td>
                                        <template>
                                            <el-input v-model.number="Params[item.name + '_lock_proportion_xjp']" maxlength="3" class="small model_font" style="width:80px;" />
                                            <span>%</span>
                                        </template>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </template> -->
            
              
            </tbody>
            <tr v-if="Params.type == 1">
                <td class="label" style="vertical-align:middle; background:#7BAAFF!important;">注意</td>
                <td colspan="6" style="text-align:left;">
                    <p>● 上级最少占成 ＋ 代理可占成數不可比  <span class="c-color-e">上限</span> 多。</p>
                    <p>● 上级收飞最大占成不可比 <span class="c-color-e">上限</span> 多。</p>
                    <p>● <span class="c-color-e">上级最大占成不可比最少占成小。</span></p>
                    <p>● 管理层走飞一律<span class="c-color-e">以 A 盘走飞。</span></p>
                    <p>● <span class="c-color-e">上级代理如设置了锁定占成，则你设置的上级代理最大占成与上级代理收飞最大占成需 >= 上级代理设置的比例。</span></p>
                </td>
            </tr>
        </table>
        <div class="c-text-r o-mt-l">
            <Button type="info" @click="Cancel()">取消</Button>
            <Button :loading="main.editing" type="info" @click="Submit()">提交</Button>
        </div>
    </el-dialog>
</template>
<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
export default {
    name: 'MyTeamUserCreditModul',
    mixins: [StoreMix],
    data() {
        return {
            store: 'credit/user',
            Params: {
                type: 0,
                status: 1,
                lhc_end_bets_date: '21:20:00',
                has_games_type: '',
                amount_type: 1,
                source: '代理添加',

                ssc_enable: false,
                ffc_enable: false,
                klc_enable: false,
                pks_enable: false,
                dpc_enable: false,
                syw_enable: false,
                lhc_enable: true,

                ssc_decrease_rebate: 0,
                ffc_decrease_rebate: 0,
                klc_decrease_rebate: 0,
                pks_decrease_rebate: 0,
                dpc_decrease_rebate: 0,
                syw_decrease_rebate: 0,
                lhc_decrease_rebate: 0,

                ssc_transfer_status: true,
                ffc_transfer_status: true,
                klc_transfer_status: true,
                pks_transfer_status: true,
                dpc_transfer_status: true,
                syw_transfer_status: true,
                lhc_transfer_status: true,

                lhc_has_bet: 'a,b,c,d,e'
            },
            agent: {
                loading: true,
                info: {}
            },
            userCheck: {
                pass: null
            }
        }
    },
    props: {
        parent: {},
        utype: {}
    },
    computed: {
        type() {
            return this.$store.state.game.group
        },
        percentage() {
            return this.$store.state.myTeam.percentage
        },
        hasDir() {
            let dir = this.agent.info.has_games_type
            let arr = []
            if (dir) {
                for (let id of dir.split(',')) {
                    for (let item of this.type._list) {
                        if (item.id == id) {
                            let has_bets = this.agent.info[`${item.code}_has_bet`].split(',')
                            let bets = {}
                            for (let bet of has_bets) {
                                bets[bet] = true
                            }
                            arr.push({
                                ...item,
                                name: item.code,
                                bets
                            })
                        }
                    }
                }
            }
            return arr
        },
        'ssc_has_bet': {get: function() {return this.Params.ssc_has_bet ? this.Params.ssc_has_bet.split(',') : []}, set: function(val) {this.$set(this.Params, 'ssc_has_bet', val.join(','))}},
        'ffc_has_bet': {get: function() {return this.Params.ffc_has_bet ? this.Params.ffc_has_bet.split(',') : []}, set: function(val) {this.$set(this.Params, 'ffc_has_bet', val.join(','))}},
        'klc_has_bet': {get: function() {return this.Params.klc_has_bet ? this.Params.klc_has_bet.split(',') : []}, set: function(val) {this.$set(this.Params, 'klc_has_bet', val.join(','))}},
        'pks_has_bet': {get: function() {return this.Params.pks_has_bet ? this.Params.pks_has_bet.split(',') : []}, set: function(val) {this.$set(this.Params, 'pks_has_bet', val.join(','))}},
        'dpc_has_bet': {get: function() {return this.Params.dpc_has_bet ? this.Params.dpc_has_bet.split(',') : []}, set: function(val) {this.$set(this.Params, 'dpc_has_bet', val.join(','))}},
        'syw_has_bet': {get: function() {return this.Params.syw_has_bet ? this.Params.syw_has_bet.split(',') : []}, set: function(val) {this.$set(this.Params, 'syw_has_bet', val.join(','))}},
        'lhc_has_bet': {get: function() {return this.Params.lhc_has_bet ? this.Params.lhc_has_bet.split(',') : []}, set: function(val) {this.$set(this.Params, 'lhc_has_bet', val.join(','))}}
    },
    watch: {
        'Params.username'(val) {
            if (val && val.length >=3 && !this.Params.id) {
                this.CheckName(val)
            }
        }
    },
    methods: {
        init() {
            this.DataReset('userCheck')
            if (!this.Params.id) {
                this.Params.type = this.utype ? 1 : 0
                this.$set(this.Params, 'lhc_end_bets_date', this.Opt.lhc_special_time)
            }
            if (this.parent && this.parent.id && !this.Params.id) {
                this.Params.pid = this.parent.id
            }
            this.DataReset('agent')
            this.Dp('myTeam/_GET_USER', {id: this.Params.pid ? this.Params.pid : this.parent.id}).then(res => {
                this.agent.loading = false
                if (this.form && this.form.has_games_type) {
                    this.$nextTick(() => {
                        for (let id of this.form.has_games_type.split(',')) {
                            for (let item of this.hasDir) {
                                if (item.id == id) {
                                    this.$set(this.Params, `${item.name}_enable`, true)
                                }
                            }
                        }
                    })
                }
                this.$set(this.agent, 'info', res.data)
                if (this.agent.info.is_admin) {
                    for (let item of this.hasDir) {
                        this.$set(this.Params, `${item.name}_proportion_min`, 0)
                        this.$set(this.Params, `${item.name}_proportion_max`, 100)
                        this.$set(this.Params, `${item.name}_transfer_rebate`, 100)
                        this.$set(this.Params, `${item.name}_proportion_min_ac`, 0)
                        this.$set(this.Params, `${item.name}_proportion_max_ac`, 100)
                        this.$set(this.Params, `${item.name}_transfer_rebate_ac`, 100)
                        this.$set(this.Params, `${item.name}_proportion_min_tw`, 0)
                        this.$set(this.Params, `${item.name}_proportion_max_tw`, 100)
                        this.$set(this.Params, `${item.name}_transfer_rebate_tw`, 100)
                        this.$set(this.Params, `${item.name}_proportion_min_xjp`, 0)
                        this.$set(this.Params, `${item.name}_proportion_max_xjp`, 100)
                        this.$set(this.Params, `${item.name}_transfer_rebate_xjp`, 100)
                        this.$set(this.Params, `${item.name}_proportion_min_xac`, 0)
                        this.$set(this.Params, `${item.name}_proportion_max_xac`, 100)
                        this.$set(this.Params, `${item.name}_transfer_rebate_xac`, 100)
                    }
                }
            })
        },
        Submit(model=this.storePath[1]) {
            let params = this.Origin(this.Params)
            let list = []
            for (let item of this.hasDir) {
                if (this.Params[`${item.name}_enable`]) {
                    list.push(item.id)
                }
            }
            if (params.id) {
                params.action = 'info'
            }
            if (this.agent.info.is_admin) {
                params._proportion = params._proportion_max
            }
            params.has_games_type = list.join(',')
            params.id ?
                this.Put(params, model) :
                this.Post(params, model)
        },
        CheckName(username) {
            this.Dp('base/CHECK_USER', username).then(res => {
                if (this.Params.username == username) {
                    this.$set(this.userCheck, 'pass', !res.data)
                }
            })
        },
        EnableGame(name, e) {
            // console.log(name,e)
        },
        CheckAmount(name, amount) {
            try {
                if (this.agent.info && this.agent.info.is_admin) {
                    return '无限制'
                }
                let origin = this.form && this.form[`${name}_amount`] ? this.form[`${name}_amount`] : 0
                console.log(name)
                let amount = this.Big(this.agent.info[`${name}_balance`]).sub(this.agent.info[`${name}_use_balance`]).toNumber()
                return amount ? amount : 0
            } catch (err) {
                return 'NaN'
            }
        }
    }
}
</script>
