<style lang="scss">
	.CreditSubIndex {
		width:100%;
		.notTeam {
			width:1080px;
		    @media screen and (max-width: 1439px){
		        width:1020px;
		    }
		}
        .home-box {
            .home-dir {
                padding:1rem 1.8rem 2rem 1.8rem;
            }
            // background:linear-gradient(180deg,#7494d4,#f5f5f5); background-size:100% 200px; background-repeat:no-repeat;
            .pack {
                width:16.66%;
                .pack-title {
                    min-height: 3rem;
                    .title {
                        color:#3266da;
                    }
                    .name {
                        padding-top:3px; color:rgba(255,255,255,.45);
                    }
                }
            }
            .pack-list {
                .pack-item {
                    padding:.3rem .45rem; cursor:pointer; transition:background-color, .3s;
                    &:hover {
                        background-color:rgba(0,0,0,.07);
                    }
                    .item-image {
                        width:2.6rem; height:2.6rem;
                    }
                }
            }
        }
	}
</style>
<template>
    <section class="CreditSubIndex" :class="{'widthTeam': Opt.isTeam || !Running.id}">
        <game-order v-show="Running.id" />
        <div v-show="!Running.id" class="home-box">
            <div class="home-dir l-flex">
                <div v-for="(pack,unit) in Category" v-show="Opt.hasDir.typeById[pack.id]" class="pack">
                    <div class="pack-title o-pl">
                        <h3 class="title c-text-b c-text-10 c-line-14">{{ pack.title }}</h3>
                        <p class="name c-text-b c-text-75 c-line-10">{{ pack.name }}</p>
                    </div>
                    <ul class="pack-list">
                        <li v-for="(item,index) in pack.games" class="pack-item" @click="ChangeGame(item,'credit')">
                            <div class="l-flex-c">
                                <img class="item-image" :src="Img(item.icon)">
                                <div class="l-flex-1 o-plr c-text-8">{{ item.title }}</div>
                            </div>
                        </li>
                        <a :href="user_link">
                <li class="pack-item">
                    <div class="l-flex-c">
                    <img src="https://api.ac6677.com/static/images/logo/quanming.jpg" class="item-image" />
                    <div class="l-flex-1 o-plr c-text-8">番摊</div>
                     </div>
                </li>
                </a>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import GameOrder from '../layout/order.vue'
export default {
    name: 'CreditSubIndex',
    data() {
        return {
            user_link : 'https://api.xinzixun.org/v3/ft?id='+localStorage.getItem('fantantoken'),
        }
    },
    watch: {
        '$route.params.code'(val, old) {
            if (val === undefined && old) {
                this.Dp('game/RESET_GAME')
            }
        }
    },
    methods: {
        ChangeGame(item) {
            let {code} = item
            this.Go('credit/game', {code, type: 'credit'})
            // this.$store.state.base.Event.emit('Credit/ChangeGame',code)
        }
    },
    components: {
        GameOrder
    }
}
</script>
