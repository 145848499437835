<style lang="scss">
@import "../../../style/config.scss";
.GameLayoutHeaderCredit {
    position:relative;
    .links {
        height:34px; line-height:34px; background-color:#c0eff7; background:linear-gradient(to bottom,#ddfcfe,#54b9cf); overflow:hidden;
        border-bottom:3px solid #47626b; position:relative;
        li, a {
            float:left; cursor:pointer;
            &.btn {
                width:72px;  text-align:center; border-right:1px solid #f3fdff;
                &:hover {
                    background-color:#fdb65c; background:linear-gradient(to bottom,#ffd8a5,#e78740); color:#e400ff;
                }
            }
        }
        @media screen and (max-width: 1439px){
            li, a {
                &.btn {
                    width:70px;
                }
            }
        }
        .tools {
            i.icon {
                font-size:17px; background-color:rgba(0,0,0,.15); color:#333; border-radius:99rem; padding:4px; cursor:pointer;
            }
        }
    }

    .modul {
        width: 100%;
        .title {
            width: 100%;
            background-color: $color-t;
            color: #fff;
            padding: 0.6rem 0;
            font-size: 18px;
        }
        .total {
            padding: 0.8rem 0.6rem 0.5rem 0.6rem;
            color: $color-t;
        }
        .content {
            padding: 0 0.6rem 0.8rem 0.6rem;
        }
    }
    .balls {
        .ball {
            width:25px; height:25px; line-height:25px; text-align:center; font-size:18px; font-weight:bold; position:relative;
            background-color:$color-t; color:#fff; border-radius:99px; margin:0 2px; margin-right:4px;
            &.red {background-color:#ec4d4d;}
            &.blue {background-color:#5f79ef;}
            &.green {background-color:#4a9a34;}
            &.white {background-color:#fff; color:#ec4d4d;}
            &.valid {
                margin-right:16px;
                .explain {
                    position:absolute; right:-14px; bottom:-1px; display:inline-block;
                    width:16px; height:16px; line-height:16px; font-size:12.5px; font-weight:normal; border-radius:3px; text-align:center;
                    color:#000;
                }
            }
        }
    }
    .special-title {
        background-color:#ffc2c2; font-size:12px; height:20px; line-height:20px; margin-left:4px; margin-right:1px; padding:0 2px;
    }
    .Tools {
        position:absolute; right:-25px; top:154px; width:25px; text-align:center;
        .btn {
            background-color:#4181dc; color:#fff; border-radius:0 10px 10px 0; cursor:pointer;
            border:1px solid #346dbd; border-left:none;
        }
    }
    .islink {
        cursor:pointer; text-decoration:underline;
    }
}
.GameLayoutModulRemind {
    min-width: 280px;
    max-width: 280px;
    width: 280px;
    .el-dialog__body {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}
</style>
<template>
    <header class="GameLayoutHeaderCredit">
        <ul class="links" :style="{background: Running.gradient_color ? `linear-gradient(to bottom,${Running.gradient_color.shallow},${Running.gradient_color.deep})` : undefined}">
            <template v-for="(item,index) in LinkDir" v-if="item.enable">
                <a v-if="item.href" class="btn" :href="item.href" target="_blank">{{ item.title }}</a>
                <li v-else class="btn" @click="item.action">{{ item.title }}</li>
            </template>
            <li v-show="Running.id" class="exhibition l-flex-c">
                <template v-if="Running.model === 7">
                    {{/* 六合彩 */}}
                    <p class="row o-plr">第 <b class="mark">{{ isTodayOpen ? Running.expect.now.expect : Running.expect.last.expect }}</b> 期</p>
                    <div v-if="ResultObj && ResultObj.result && ResultObj.result.length" class="balls l-flex-c" :class="{'ball-small': ResultObj.result.length > 8}">
                        <span v-for="item in ResultObj.result" class="ball" :class="[item.color,{'valid': item.code,'white': !item.code}]">
                            <span>{{ item.code ? item.code : '?' }}</span>
                            <span v-if="item.text" class="explain">{{ item.text }}</span>
                        </span>
                        <template v-if="ResultObj.special && ResultObj.special.length">
                            <b class="special-title">特别号</b>
                            <span v-for="item in ResultObj.special" class="ball" :class="[item.color,{'valid': item.code,'white': !item.code}]">
                                <span :class="item.color">{{ item.code ? item.code : '?' }}</span>
                                <span v-if="item.text" class="explain">{{ item.text }}</span>
                            </span>
                        </template>
                        <template v-else>
                            <b class="special-title">特别号</b>
                            <span class="ball white"><span>?</span></span>
                        </template>
                    </div>
                    <div v-else class="balls l-flex-c">
                        <span v-for="item in 7" class="ball white"><span>?</span></span>
                        <b class="special-title">特别号</b>
                        <span class="ball white"><span>?</span></span>
                    </div>
                </template>
                <template v-else>

                    {{/* 其他彩种 */}}
                    <p class="row o-plr">第 <b class="mark">{{ Running.expect.last.expect }}</b> 期</p>
                    <div class="balls l-flex-c" :class="{'ball-small': ResultObj.result.length > 8}">
                        <span v-for="item in ResultObj.result" class="ball">
                            <span>{{ item.code ? item.code : '?' }}</span>
                            <span v-if="item.text" class="explain">{{ item.text }}</span>
                        </span>
                    </div>
                </template>
            </li>
            <div v-if="Opt.isTeam" class="l-flex-c">
                <div class="l-flex-1 o-pr-h c-text- c-text-r">
                    <span class="islink" @click="Go('credit/online')">在线人数（代理：{{ online.online_agent }}，会员：{{ online.online_member }}，最高：{{ online.online_max }}）</span>
                </div>
                <div class="o-pr">
                    <span>欢迎，{{ User.username }}</span>
                    <span v-if="Opt.isAdmin">管理员</span>
                    <span v-else>代理</span>
                </div>
            </div>
        </ul>
        <el-dialog :title="Running.title" custom-class="GameLayoutModulRemind" :visible.sync="remind.view" center>
            <p class="c-text-75 c-text-c">请注意，期号已切换</p>
            <p class="c-text-75 o-mt-s c-text-c">当前下注期号为</p>
            <p class="c-text-85 o-mt-s c-text-c"><b class="c-color-t">{{ remind.next }}</b></p>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="remind.view = false">确 定</el-button>
            </div>
        </el-dialog>

        <div class="Tools" style="z-index:100;">
            <el-popover placement="left-start" width="230" trigger="hover">
                <div class="">
                    <p class="c-line-14">字体大小设置</p>
                    <el-radio-group v-model="Tools.opt.fontsize" size="mini" @change="ToolFontSize($event)">
                        <el-radio-button :label="20">默认</el-radio-button>
                        <el-radio-button :label="21.5">普通</el-radio-button>
                        <el-radio-button :label="22.5">偏大</el-radio-button>
                        <el-radio-button :label="24.5">最大</el-radio-button>
                    </el-radio-group>
                </div>
                <div slot="reference" class="btn o-ptb">
                    <p>设</p>
                    <p>置</p>
                </div>
            </el-popover>
        </div>
    </header>
</template>

<script>
import Common from './header.js'
export default {
    name: 'GameLayoutHeaderCredit',
    mixins: [Common],
    data() {
        return {

        }
    },
    computed: {
        isTodayOpen() {
            if (this.Running.model != 7) {
                return true
            }
            if (this.Running.expect.now.id) {
                let time = this.Running.expect.time
                let close = this.Running.expect.now.close
                return this.Time(time, 'yyyy-MM-dd') === this.Time(close, 'yyyy-MM-dd')
            }
            return false
        },
        ResultOut() {
            // 如果今天不开奖，显示昨天的数据
        },
        LinkDir() {
            if (this.User) {
                return [
                    {
                        title: '番摊',
                        enable: true,
                        action: () => {
                            return window.location.href = 'https://api.xinzixun.org/v3/ft?id='+localStorage.getItem('fantantoken');
                        }
                    },
                    {
                        title: '游戏首页',
                        enable: this.CheckAuth(!this.Opt.isTeam),
                        action: () => {
                            this.Dp('game/RESET_GAME')
                            return this.Go('credit/game', {code: undefined, type: undefined})
                        }
                    },
                    {
                        title: '下注明细',
                        enable: this.CheckAuth(!this.Opt.isTeam),
                        action: () => {
                            return this.Go('credit/query')
                        }
                    },
                    {
                        title: '结算报表',
                        enable: this.CheckAuth(!this.Opt.isTeam),
                        action: () => {
                            return this.Go('credit/report')
                        }
                    },
                    {
                        title: '代理',
                        enable: this.CheckAuth(this.Opt.isTeam && !this.Opt.isAdmin, 10),
                        action: () => {
                            return this.Go('credit/agent')
                        }
                    },
                    {
                        title: '开奖历史',
                        enable: true,
                        action: () => {
                            return this.$emit('output');
                        }
                    },
                    {
                        title: '注单备份',
                        enable: this.CheckAuth(this.Opt.isTeam, 11),
                        action: () => {
                            return this.Go('credit/backup')
                        }
                    },
                    {
                        title: '会员',
                        enable: this.CheckAuth(!this.Opt.isTeam),
                        action: () => {
                            return this.Go('credit/member')
                        }
                    },
                    {
                        title: '规则',
                        enable: true,
                        action: () => {
                            return this.Go('credit/rules')
                        }
                    },
                    {
                        title: '公告',
                        enable: true,
                        action: () => {
                            return this.$emit('announ')
                        }
                    },
                    {
                        title: '登出',
                        enable: true,
                        action: () => {
                            return this.Logout()
                        }
                    }
                ]
            }
            return []
        }
    },
    methods: {
        CheckAuth(origin, key) {
            if (!origin) {
                return false
            }
            if (this.Opt.isSub && key) {
                if (!this.Opt.isSub[key]) {
                    return false
                }
            }
            return true
        }
    },
    components: {

    }
}
</script>
